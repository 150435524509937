import { useEffect, useState } from "react";
import httpClient from "../services/rest";
import { useDispatch } from "react-redux";
import { handleGeneralErrorAsync } from "../sagas/actions";
import { replaceInBrackets } from "../utils";

const useContactsOfSegmentation = (
  segmentation,
  page = 1,
  itemsPerPage = 20
) => {
  const [contacts, setContacts] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setLoading(true);
  }, [page, itemsPerPage]);

  useEffect(() => {
    const fetching = async () => {
      try {
        const contactLink = segmentation._links.contact.href;
        const contactsList = await httpClient.get(contactLink);
        const findLink = contactsList.data._links.find.href;
        const findLinkWithQuery = replaceInBrackets(
          findLink,
          ["page", "resourcesPerPage"],
          [page, itemsPerPage]
        );
        const queryContacts = await httpClient.get(findLinkWithQuery);
        setTotal(queryContacts.data.total);
        const subList = queryContacts.data._embedded.contact;
        setContacts(subList);
      } catch (e) {
        dispatch(handleGeneralErrorAsync(e));
      }
      setLoading(false);
    };
    if (loading) {
      fetching();
    }
  }, [loading]);

  return { contacts, loading, total };
};

export default useContactsOfSegmentation;
