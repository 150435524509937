const atendSections = () => [
  {
    title: "Sem Atendimento",
    filterFunc: (conversation) =>
      Array.from(conversation.messagesEntity.messagesByIndex.values())
        .length === 0 ||
      Array.from(conversation.participants.values()).every(
        (item) => item.state.type !== "chat"
      ),
  },
  {
    title: "Com Atendimento",
    filterFunc: (conversation) =>
      Array.from(conversation.messagesEntity.messagesByIndex.values()).length >
        0 &&
      Array.from(conversation.participants.values()).some(
        (item) => item.state.type === "chat"
      ),
  },
];

const segSections = (segmentations) =>
  segmentations.map((seg) => ({
    title: seg.title,
    filterFunc: (conversation) =>
      seg.contacts.some(
        (cont) => cont.conversation.twilioConversationSid === conversation.sid
      ),
  }));

const myAtendSections = (identity) => [
  {
    title: "Atendidos por mim",
    filterFunc: (conversation) =>
      Array.from(conversation.messagesEntity.messagesByIndex.values()).some(
        (item) => item.author === identity
      ),
  },
  {
    title: "Não atendidos por mim",
    filterFunc: (conversation) =>
      Array.from(conversation.messagesEntity.messagesByIndex.values()).every(
        (item) => item.author !== identity
      ),
  },
];

export { myAtendSections, atendSections, segSections };
