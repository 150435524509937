export const SAGA_TYPES = {
  SHOW_ALERT_FOR_HTTP_RESPONSE: "SHOW_ALERT_FOR_HTTP_RESPONSE",
  PATCH: "PATCH",
  DELETE: "DELETE",
  REQUEST: "REQUEST",
  HANDLE_HYPERLINK_UPDATE: "HANDLE_HYPERLINK_UPDATE",
  HANDLE_EXPORTATION: "HANDLE_EXPORTATION",
  HANDLE_GENERAL_ERROR: "HANDLE_GENERAL_ERROR",
  SHOW_ALERT_SAGA: 'SHOW_ALERT_SAGA',
};

// ACTIONS DE HATEOAS
export const handleHyperlinkUpdateAsync = (href, title, history) => ({
  type: SAGA_TYPES.HANDLE_HYPERLINK_UPDATE,
  payload: {
    history,
    successorLink: {
      href,
      title,
    },
  },
});

// ACTIONS DE UI
export const showAlertForHTTPResponseAsync = (response, type = undefined) => ({
  type: SAGA_TYPES.SHOW_ALERT_FOR_HTTP_RESPONSE,
  payload: { response, type },
});

export const showAlertSaga = ({ type, message }) => ({
  type: SAGA_TYPES.SHOW_ALERT_SAGA,
  payload: { alertData: { type, message } },
});

// ACTIONS DE CRUD
export const requestSaga = (
  method,
  href,
  data = {},
  onSuccess = () => {},
  onError = () => {},
  history = null,
  showAlert = true,
  alertType = undefined,
) => ({
  type: SAGA_TYPES.REQUEST,
  payload: { method, href, data, onError, onSuccess, history, showAlert, alertType },
});

export const handleExportation = (files, history) => ({
  type: SAGA_TYPES.HANDLE_EXPORTATION,
  payload: { files, history },
});

export const handleGeneralErrorAsync = (error, history) => ({
  type: SAGA_TYPES.HANDLE_GENERAL_ERROR,
  payload: { error, history },
});

