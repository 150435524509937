import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import BackgroundAndCard from "../../components/BackgroundAndCard";
import moment from "moment";
import TemplateSelector from "./components/TemplateSelector";
import FillTemplateStage from "./components/FillTemplateStage";
import DateTimesSelector from "./components/DateTimesSelector";
import SegmentationSelector from "./components/SegmentationSelector";
import Resume from "./components/Resume";
import { useDispatch } from "react-redux";
import { requestSaga } from "../../sagas/actions";
import { useHistory } from "react-router";
const getMomentNow = () => moment(moment.now());

const Schedule = () => {
  const [stage, setStage] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [template, setTemplate] = useState();
  const [dateTimes, setDateTimes] = useState([getMomentNow()]);
  const [segmentation, setSegmentation] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const titles = [
    "Selecione um modelo",
    "Preencha a mensagem a ser enviada",
    "Selecione data e hora",
    "Selecione uma segmentação",
    "Resumo",
  ];

  const onSchedule = async () => {
    const link = segmentation._links["make-schedule"].href;
    const isos = dateTimes.map((item) => item.format());
    dispatch(
      requestSaga(
        "patch",
        link,
        {
          scheduledFor: isos,
          message: template,
        },
        () => {
          setStage(0);
          setTemplate("");
          setDateTimes([getMomentNow()]);
          setSegmentation(null);
        },
        () => {},
        history
      )
    );
  };

  const onContinue = () => {
    if (stage === 4) return onSchedule();
    setStage((prev) => prev + 1);
  };
  const onGoBack = () => setStage((prev) => prev - 1);

  const onClickTemplate = (item) => {
    setSelectedTemplate(item);
    onContinue();
  };

  const onSelectContact = (sgmt) => {
    setSegmentation(sgmt);
    onContinue();
  };

  const onChangeDate = (newDate, id) => {
    const newDates = dateTimes.map((item, index) =>
      index === id ? newDate : item
    );
    setDateTimes(newDates);
  };

  const onAddDate = () => {
    setDateTimes((prev) => prev.concat([getMomentNow()]));
  };

  const onRemoveDate = (index) => {
    const newArray = [...dateTimes];
    newArray.splice(index, 1);
    setDateTimes(newArray);
  };
  return (
    <BackgroundAndCard>
      <Box display="flex" height={0.1} alignItems="center">
        {stage !== 0 && (
          <IconButton onClick={onGoBack}>
            <Icon style={{ color: "black" }}>arrow_back</Icon>
          </IconButton>
        )}
        <Typography variant="h5">{titles[stage]}</Typography>
      </Box>
      <Box
        width={0.95}
        height={0.8}
        alignSelf="center"
        display="flex"
        flexDirection="column"
        overflow="auto"
      >
        {stage === 0 && <TemplateSelector onClickTemplate={onClickTemplate} />}
        {stage === 1 && (
          <FillTemplateStage
            templateBody={selectedTemplate.body}
            onChange={setTemplate}
          />
        )}
        {stage === 2 && (
          <DateTimesSelector
            dateTimes={dateTimes}
            onChange={onChangeDate}
            onAddDate={onAddDate}
            onRemoveDate={onRemoveDate}
          />
        )}
        {stage === 3 && <SegmentationSelector onSelect={onSelectContact} />}
        {stage === 4 && <Resume data={{ template, dateTimes, segmentation }} />}
      </Box>
      {stage !== 0 && stage !== 3 && (
        <Box height={0.1} display="flex" flexDirection="column">
          <Divider style={{ marginBottom: 10, marginTop: 10 }} />
          <Button
            style={{ marginTop: "auto", alignSelf: "flex-end" }}
            variant="outlined"
            color="primary"
            onClick={onContinue}
          >
            Continuar
          </Button>
        </Box>
      )}
    </BackgroundAndCard>
  );
};

export default Schedule;
