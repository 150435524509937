import React from "react";
import { useDispatch } from "react-redux";
import Background from "../../components/Background";
import { logout } from "../../store";
import ListAndContentCard from "../../components/ListAndContentCard";

const Config = () => {
  const dispatch = useDispatch();
  const ITEMS = [
    // {
    //   Component: TestContent,
    //   contentId: "something",
    //   title: "Something",
    //   description:
    //     "Esse é um teste, vou aproveitar pra tentar quebrar a linha com um texto bem grande e bacanudo.",
    // },
    // {
    //   Component: TestContent,
    //   contentId: "other_something",
    //   title: "Other Something",
    //   description:
    //     "Esse é um teste, vou aproveitar pra tentar quebrar a linha com um texto bem grande e bacanudo.",
    // },
    {
      contentId: null,
      title: "Sair",
      // description: "Lorem ipsum e coisa e tal xuxu beleza.",
      iconName: "logout",
      color: "error",
      onClick: () => dispatch(logout()),
    },
  ];

  return (
    <Background
      headerTitle="Configurações"
      contentStyle={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ListAndContentCard items={ITEMS} />
    </Background>
  );
};

export default Config;
