import React from "react";
import Icon from "@material-ui/core/Icon";

const CustomIcon = ({ name, size = 20, color = "", style = {}, src = {} }) => {
  if (name)
    return (
      <Icon color={color || "default"} style={{ fontSize: size, ...style }}>
        {name}
      </Icon>
    );
  return (
    <img
      alt="ícone"
      src={src}
      style={{ width: size, height: size, ...style }}
    />
  );
};

export default CustomIcon;
