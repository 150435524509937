import {
  ButtonBase,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { DRAWER_WIDTH } from "../../theme/styleConstants";
import CustomIcon from "../CustomIcon";
import { CircularStyled } from "./styles";

const Circular = ({
  size = 58,
  backgroundColor,
  iconProps,
  containerStyle = {},
  shadow = "",
  onClick,
}) => {
  return (
    <CircularStyled
      onClick={onClick}
      backgroundColor={backgroundColor}
      size={size}
      shadow={shadow}
      containerStyle={containerStyle}
    >
      {iconProps !== {} && <CustomIcon {...iconProps} />}
    </CircularStyled>
  );
};

const AddButton = ({ onClick, iconStyle, containerStyle }) => (
  <IconButton
    style={{ alignSelf: "center", ...containerStyle }}
    onClick={onClick}
  >
    <Icon style={iconStyle}>add</Icon>
  </IconButton>
);

const IconWithLabel = ({
  text,
  color,
  onClick,
  iconName,
  containerStyle,
  tooltipOpen,
  onOpen = () => {},
}) => {
  return (
    <Tooltip
      placement="right"
      title="Nova mensagem!"
      disableHoverListener
      open={tooltipOpen}
      onOpen={onOpen}
    >
      <ButtonBase
        onClick={onClick}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: 10,
          paddingBottom: 10,
          ...containerStyle,
        }}
      >
        <CustomIcon name={iconName} color={color} />
        <div
          style={{
            width: DRAWER_WIDTH,
          }}
        >
          <Typography
            style={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
            variant="caption"
            color={color || "textPrimary"}
          >
            {text}
          </Typography>
        </div>
      </ButtonBase>
    </Tooltip>
  );
};

const components = {
  Circular,
  IconWithLabel,
  AddButton,
};

export default components;
