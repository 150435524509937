import React, { useEffect, useState } from "react";
import { List, Drawer as MuiDrawer } from "@material-ui/core";
import Button from "../components/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../store/userSlice";
import { NewChatImage } from "../assets/images";
import { DRAWER_WIDTH } from "../theme/styleConstants";
import { getFirstPath } from "../utils";
import {
  resetLinkHistory,
  setDisplayedTitle,
  setShowNewMessage,
} from "../store/uiSlice";
import NewConversationDialog from "../components/NewConversationDialog";
import { logout } from "../store";
import { requestSaga } from "../sagas/actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "flex",
    paddingTop: 16,
    backgroundColor: theme.palette.primary[100],
    zIndex: theme.zIndex.drawer,
  },
}));

const Drawer = () => {
  const { isLogged, info } = useSelector(userSelector);
  const { navigationLinks, showNewMessage } = useSelector((state) => state.ui);
  const [newConversationOpen, setNewConversationOpen] = useState();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [schema, setSchema] = useState({});
  const [contactsLink, setContactsLink] = useState("");
  const { twilioAccessToken } = useSelector((state) => state.user.info);
  const customRequestSaga = (href, callback) => {
    return requestSaga(
      "get",
      href,
      undefined,
      callback,
      undefined,
      null,
      false
    );
  };
  useEffect(() => {
    const fetch = async () => {
      try {
        dispatch(
          customRequestSaga("/", (response1) => {
            dispatch(
              customRequestSaga(response1?.data?._links?.contacts?.href, () => {
                const contactsLink = response1?.data?._links?.contacts?.href;
                setContactsLink(contactsLink);
                dispatch(
                  customRequestSaga(contactsLink, (response3) => {
                    const schemaLink =
                      response3?.data?._links?.describedby?.href;
                    dispatch(
                      customRequestSaga(schemaLink, (response4) => {
                        setSchema(response4?.data);
                      })
                    );
                  })
                );
              })
            );
          })
        );
      } catch (e) {
        console.log(e);
      }
    };
    if (isLogged) {
      fetch();
    }
  }, [isLogged]);

  const itemsList = [
    {
      text: "Atender",
      route: "/atender",
      iconName: "forum",
    },
    {
      text: "Agendar",
      route: "/agendar",
      iconName: "send",
    },
    {
      text: "Gerir",
      route: "/gerir",
      iconName: "view_list",
    },
    {
      text: "Sair",
      route: "/configuracoes",
      iconName: "logout",
    },
  ];
  useEffect(() => {
    if (showNewMessage) {
      setTimeout(() => {
        dispatch(setShowNewMessage({ isOpen: false }));
      }, 3000);
    }
  }, [showNewMessage]);

  if (!isLogged || !navigationLinks?.[Object?.keys(navigationLinks)[0]]?.href) {
    return <Redirect to="/" />;
  }

  const renderItem = (route) => {
    if (route !== "/atender") return true;
    if (twilioAccessToken) return true;
    return false;
  };

  return (
    <MuiDrawer
      PaperProps={{ elevation: theme.elevation.background }}
      variant="persistent"
      classes={{ paper: classes.paper }}
      open
    >
      {info?.user?.accessProfile?.id !== 1 && (
        <div
          style={{ display: "flex", marginLeft: "auto", marginRight: "auto" }}
        >
          <Button.Circular
            onClick={() => setNewConversationOpen(true)}
            iconProps={{ src: NewChatImage }}
            backgroundColor={theme.palette.secondary.main}
          />
        </div>
      )}
      <List
        style={{
          overflow: "auto",
        }}
      >
        {itemsList.map(({ text, route, iconName }) => (
          <>
            {renderItem(route) && (
              <Button.IconWithLabel
                tooltipOpen={
                  showNewMessage &&
                  route === "/atender" &&
                  location.pathname !== "/atender"
                }
                key={route}
                text={text.toUpperCase()}
                iconName={iconName}
                onClick={() => {
                  if (route === "/configuracoes") {
                    return dispatch(logout());
                  }
                  if (route === "/gerir") {
                    dispatch(resetLinkHistory());
                  }
                  history.push(route);
                  dispatch(
                    setDisplayedTitle({
                      displayedTitle: text,
                    })
                  );
                }}
                color={
                  route === "/configuracoes"
                    ? "error"
                    : route === getFirstPath(location.pathname)
                    ? "secondary"
                    : null
                }
              />
            )}
          </>
        ))}
      </List>
      {newConversationOpen && (
        <NewConversationDialog
          newConversationOpen={newConversationOpen}
          onClose={() => setNewConversationOpen(false)}
          schema={schema}
          contactsLink={contactsLink}
        />
      )}
    </MuiDrawer>
  );
};

export default Drawer;
