import React from "react";

const Renderer = ({ selectedItem = {} }) => {
  return (
    <div style={{ width: "66%" }}>
      {selectedItem.Component &&
        selectedItem.Component({ id: selectedItem.contentId })}
    </div>
  );
};

export default Renderer;
