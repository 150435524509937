import { Box, Typography, Divider, ButtonBase } from "@material-ui/core";
import React from "react";
import CustomIcon from '../CustomIcon'

const Default = ({ title, height, rightButtonProps = null, ...rest }) => {
  return (
    <>
      <Box
        height={height || "9vh"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...rest}
      >
        <Box flex={1} />
        <Box>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
          {rightButtonProps && (<ButtonBase onClick={rightButtonProps.onClick}><CustomIcon name={rightButtonProps.iconName} size={rightButtonProps.size} /></ButtonBase>)}
        </Box>
      </Box>
      <Divider />
    </>
  );
};

const components = {
  Default,
};

export default components;
