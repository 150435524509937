import React from "react";
import TemplateList from "../../../../components/TemplateList";

const TemplateSelector = ({ onClickTemplate }) => {
  return (
    <TemplateList
      height={1}
      gridTemplateColumns="33% 33% 33%"
      onClickTemplate={onClickTemplate}
    />
  );
};

export default TemplateSelector;
