import React, { useEffect, useState } from "react";
import { Box, ListItem, Typography } from "@material-ui/core";
import { formatDateToChat } from "../../../../utils";
import theme from "../../../../theme";
import useStyles, { LightTooltip } from "./styles";

const ConversationsListItem = ({
  item,
  selectedConversationSid,
  onConversationClick,
  conversations,
  identity,
}) => {
  const activeChannel = item.sid === selectedConversationSid;
  const [unreadCount, setUnreadCount] = useState(0);
  const [lastMessage, setLastMessage] = useState(0);

  useEffect(() => {
    let unreadCount = 0;
    item.messagesEntity.messagesByIndex.forEach((v, k) => {
      if (
        k > item.lastReadMessageIndex ||
        (!item.lastReadMessageIndex && item.lastReadMessageIndex !== 0)
      ) {
        unreadCount += 1;
      }
    });
    const fetchUnreadCount = async () => {
      setUnreadCount(unreadCount);
      setLastMessage(
        item?.messagesEntity?.messagesByIndex?.get(item?.lastMessage?.index)
      );
    };
    fetchUnreadCount();
  }, [
    item,
    conversations,
    selectedConversationSid,
    item.messagesEntity.messagesByIndex.size,
  ]);
  const isUnread = unreadCount > 0;
  const unreadStyle = isUnread ? { color: theme.palette.secondary.main } : {};
  const classes = useStyles({ activeChannel });
  const participant = lastMessage?.conversation?.participants.get(
    lastMessage?.state?.participantSid
  );
  const isAtendente = participant?.type === "chat";
  return (
    <LightTooltip
      title={lastMessage?.body}
      disableHoverListener={!lastMessage?.body}
      placement="bottom-end"
    >
      <ListItem
        button
        key={item.sid}
        onClick={() => onConversationClick(item)}
        classes={{ root: classes.container }}
        divider
      >
        <Box width={0.2}></Box>
        <Box display="flex" flexDirection="column" width={0.5} height={0.9}>
          <Typography
            variant="subtitle1"
            classes={{ root: classes.messageTitle }}
          >
            {item.friendlyName}
          </Typography>
          {!!lastMessage && (
            <Typography
              variant="body2"
              style={{
                opacity: 0.6,
                ...unreadStyle,
              }}
              classes={{ root: classes.lastMsgBox }}
            >
              <Box display="inline" fontWeight="fontWeightBold">
                {identity === lastMessage?.author
                  ? "Você"
                  : isAtendente
                  ? "Atendente"
                  : "Contato"}
                :
              </Box>{" "}
              {lastMessage?.body}
            </Typography>
          )}
        </Box>
        <Box
          width={0.2}
          display="flex"
          height={0.8}
          justifyContent="space-between"
          alignItems="flex-end"
          marginLeft="auto"
          flexDirection="column"
        >
          <Typography variant="caption" style={unreadStyle}>
            {item.lastMessage
              ? formatDateToChat(item?.lastMessage?.dateCreated, true)
              : null}
          </Typography>
          {isUnread && (
            <Typography style={unreadStyle}>{unreadCount}</Typography>
          )}
        </Box>
      </ListItem>
    </LightTooltip>
  );
};

export default ConversationsListItem;
