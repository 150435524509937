import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import {
  requestSaga,
  showAlertForHTTPResponseAsync,
} from "../../sagas/actions";
import { userSelector } from "../../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { WelcomeImage } from "../../assets/images";
import { LoginButton, Container, Wrapper } from "./styles";
import RJSForm from "../../components/RJSForm";
import { requiredFields } from "../../utils/validations";
import { POST_LOGIN } from "../../services/routes";
import { customAlertDatasTypes } from "../../sagas/ui";
import { login as reduxLogin } from "../../store/userSlice";
import { getSchema } from "../../services/login";
import { setNavigationLinks } from "../../store/uiSlice";

const schema = {
  type: "object",
  properties: {
    email: {
      type: "string",
      title: "Email",
    },
    password: {
      type: "string",
      title: "Senha",
    },
  },
};

const uiSchema = {
  email: {
    "ui:widget": "email",
  },
  password: {
    "ui:widget": "password",
  },
};

const validate = (formData, errors) => {
  requiredFields(formData, errors);
  return errors;
};

const Login = () => {
  const { isLogged } = useSelector(userSelector);
  const [formData, setFormData] = useState();
  const dispatch = useDispatch();
  const [liveValidate, setLiveValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const { navigationLinks } = useSelector((state) => state.ui);
  const isDev = process.env.NODE_ENV === "development";
  const handleChange = ({ formData }) => {
    setLiveValidate(true);
    setFormData(formData);
  };

  const proccessFields = ({ email, password }) => {
    const newEmail = email.trim();

    return {
      email: newEmail,
      password,
    };
  };

  const onSuccessLogin = async (response) => {
    localStorage.setItem("token", response.data.access_token);
    dispatch(
      reduxLogin({
        twilioAccessToken: response.data.twilioAccessToken,
        twilioIdentity: response.data.twilioIdentity,
        user: response.data._embedded.user,
      })
    );
    try {
      const response2 = await getSchema();
      dispatch(setNavigationLinks({ navigationLinks: response2._links }));
    } catch (e) {
      dispatch(showAlertForHTTPResponseAsync(e?.response));
    }
    setLoading(false);
  };

  const handleSubmit = async ({ formData }) => {
    setLoading(true);
    dispatch(
      requestSaga(
        "post",
        POST_LOGIN,
        proccessFields(formData),
        onSuccessLogin,
        (e) => setLoading(false),
        undefined,
        true,
        customAlertDatasTypes.login
      )
    );
  };

  if (isLogged && navigationLinks?.[Object?.keys(navigationLinks)[0]]?.href) {
    return (
      <Redirect to={navigationLinks[Object.keys(navigationLinks)[0]].href} />
    );
  }

  return (
    <Container>
      <Wrapper width="55%">
        <Box style={{ width: "80%" }}>
          {!isDev && (
            <img
              alt="Imagem Inicial"
              src={WelcomeImage}
              style={{ width: "100%" }}
            />
          )}
          {isDev ? (
            <Typography variant="h1" color="error">
              ESTA É A VERSÃO DE DESENVOLVIMENTO!
            </Typography>
          ) : (
            <Typography variant="h4">
              Bem-vindo(a), à plataforma de mensageria e atendimento da Expandr!
            </Typography>
          )}
        </Box>
      </Wrapper>
      <Wrapper width="45%" alignItems="center">
        <Grid
          item
          direction="column"
          container
          alignItems="center"
          style={{ width: "50%" }}
        >
          <RJSForm
            onChange={handleChange}
            onSubmit={handleSubmit}
            schema={schema}
            uiSchema={uiSchema}
            formData={formData}
            validate={validate}
            liveValidate={liveValidate}
          >
            <LoginButton loading={loading}>
              {loading ? <CircularProgress color="secondary" /> : "Entrar"}
            </LoginButton>
          </RJSForm>
        </Grid>
      </Wrapper>
    </Container>
  );
};

export default Login;
