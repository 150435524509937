import { Collapse } from "@material-ui/core";
import React, { useState } from "react";
import TemplateDialog from "../TemplateDialog";
import TemplateList from "../../../../components/TemplateList";

const TemplateCollapse = ({ open, onConfirmTemplate, height = "50vh" }) => {
  const [isTemplateDialogOpen, setIsTemplateDialogOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const onClickTemplate = (item) => {
    setIsTemplateDialogOpen(true);
    setSelectedTemplate(item);
  };

  return (
    <>
      <Collapse in={open} collapsedSize={0}>
        <TemplateList bgcolor="white" onClickTemplate={onClickTemplate} />
      </Collapse>
      {isTemplateDialogOpen && (
        <TemplateDialog
          isDirect
          selectedTemplate={selectedTemplate}
          onClose={() => setIsTemplateDialogOpen(false)}
          open={isTemplateDialogOpen}
          onConfirm={onConfirmTemplate}
        />
      )}
    </>
  );
};

export default TemplateCollapse;
