import { Box, Typography, useTheme } from "@material-ui/core";
import React from "react";

const Resume = ({ data }) => {
  const theme = useTheme();
  return (
    <Box
      margin="auto"
      bgcolor="white"
      borderRadius={20}
      padding={4}
      width={"50%"}
      border={`1px solid ${theme.palette.primary.main}`}
      overflow="auto"
    >
      <Typography variant="h6">
        Segmentação:{" "}
        <Typography variant="subtitle1">{data.segmentation.title}</Typography>
      </Typography>
      <Typography variant="h6">Datas e Horas: </Typography>
      {data.dateTimes.map((item, index) => (
        <Typography variant="subtitle1">
          {index + 1}º - {item.format("D/MM/YYYY, HH:mm:ss")}
        </Typography>
      ))}
      <Typography variant="h6">Corpo da mensagem:</Typography>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Typography
          style={{
            display: "flex",
            backgroundColor: theme.palette.secondary.light,
            padding: 6,
            borderRadius: 14,
            borderTopRightRadius: 0,
          }}
        >
          {data.template}
        </Typography>
      </Box>
    </Box>
  );
};

export default Resume;
