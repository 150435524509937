import { Widgets } from "@rjsf/material-ui";
import React from "react";
import Phone from "../Phone";

const Text = (props) => {
  return props?.schema?.title === "WhatsApp do Contato" || props?.schema?.title === 'WhatsApp do Cliente' ? (
    <Phone {...props} />
  ) : (
    <Widgets.TextWidget {...props} />
  );
};

export default Text;
