import { call, put, takeEvery, all } from "redux-saga/effects";
import { hideAlert, showAlert } from "../store/uiSlice";
import { SAGA_TYPES, showAlertSaga as SAS } from "./actions";

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

export const customAlertDatasTypes = {
  login: "login",
};

const customAlertDatas = {
  login: {
    200: { message: "Login efetuado com sucesso!", type: "success" },
    401: {
      message: "Os dados inseridos não correspondem a nenhum usuário.",
      type: "error",
    },
  },
  authorization: {
    401: {
      message: "Sua sessão expirou.",
      type: "error",
    },
    403: {
      message: "Você não tem autorização para esta ação.",
      type: "warning",
    },
    422: {
      message: "Algo inesperado aconteceu.",
      type: "error",
    },
  },
};

export function* showAlertForHTTPResponseAsync({
  payload: { response, type },
}) {
  try {
    let alertData = {};
    const status = response?.status;
    if (type && status in customAlertDatas[type]) {
      alertData = customAlertDatas[type][status];
    } else if ([200, 201, 204, 205].includes(status)) {
      alertData.message = "Sucesso!";
      alertData.type = "success";
    } else if ([500].includes(status)) {
      alertData.message = "Algo inesperado aconteceu.";
      alertData.type = "warning";
    } else { // 400, 401, 404 e Outros
      alertData.message = "Erro!";
      alertData.type = "error";
    }
    if (response?.data?.message) {
      alertData.message = response.data.message;
    }
    yield put(SAS(alertData));
  } catch (e) {
    console.log(e);
  }
}

export function* showAlertSaga({
  payload: { alertData },
}) {
  yield put(showAlert(alertData));
  yield call(delay, 5000);
  yield put(hideAlert());
}


export default function* rootSaga() {
  yield all([takeEvery(
    SAGA_TYPES.SHOW_ALERT_FOR_HTTP_RESPONSE,
    showAlertForHTTPResponseAsync,
  ), takeEvery(SAGA_TYPES.SHOW_ALERT_SAGA, showAlertSaga)]);
}
