import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  List,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { DataGrid, ptBR } from "@material-ui/data-grid";
import React, { useState } from "react";
import useContactsOfSegmentation from "../../../../hooks/useContactsOfSegmentation";
import useSegmentations from "../../../../hooks/useSegmentations";
const ContactsDialog = ({ open, onClose, item }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { contacts, loading, total } = useContactsOfSegmentation(
    item,
    page,
    pageSize
  );

  const onPageChange = ({ page }) => {
    setPage(page + 1);
  };

  const onPageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    setPage(1);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <Typography variant="h6">Contatos</Typography>
        <DataGrid
          columns={[
            { field: "name", headerName: "Nome", flex: 1 },
            { field: "whatsAppNumber", headerName: "Número do Whatsapp", flex: 1 },
          ]}
          loading={loading}
          rows={contacts}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          page={page - 1}
          rowCount={total}
          paginationMode="server"
          pageSize={pageSize}
          onPageChange={onPageChange}
          autoHeight
          onPageSizeChange={onPageSizeChange}
          rowsPerPageOptions={[20, 40, 60]}
          componentsProps={{
            pagination: {
              labelRowsPerPage: "Linhas por página",
              labelDisplayedRows: ({ from, to, count }) =>
                `${from}-${to} de ${count}`,
            },
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const AccordionItem = ({ item, onSelect }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <Accordion key={item.id} expanded={false}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box
            display="flex"
            width={1}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>{item.title}</Typography>
            <Box justifyContent="center" display="flex">
              <Button
                variant="outlined"
                style={{ marginRight: 10 }}
                color="secondary"
                onClick={() => setDialogOpen((prev) => !prev)}
              >
                Ver contatos
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onSelect(item)}
              >
                Selecionar
              </Button>
            </Box>
          </Box>
        </AccordionSummary>
      </Accordion>
      <ContactsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        item={item}
      />
    </>
  );
};

const SegmentationSelector = ({ onSelect }) => {
  const [page, setPage] = useState(1);

  const { segmentations, loading, total } = useSegmentations(page, 20);
  const onLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  return (
    <Box>
      {!loading && segmentations.length === 0 && (
        <Typography>Não há segmentações disponíveis.</Typography>
      )}
      <List>
        {[...segmentations].map((item) => (
          <AccordionItem item={item} onSelect={onSelect} />
        ))}
      </List>
      {loading && <CircularProgress />}
      {total > segmentations.length && !loading && (
        <Tooltip title={"Mostrar mais segmentações"} placement="top">
          <IconButton style={{ alignSelf: "center" }} onClick={onLoadMore}>
            <Icon>more_horiz</Icon>
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default SegmentationSelector;
