import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import userSlice from "./userSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import uiSlice from "./uiSlice";
import hateoasSlice from "./hateoasSlice";
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const LOGOUT = "LOGOUT";

export const logout = () => ({
  type: LOGOUT,
});

const appReducer = combineReducers({
  user: userSlice,
  ui: uiSlice,
  hateoas: hateoasSlice,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    localStorage.removeItem("token");
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware,
});

sagaMiddleware.run(rootSaga);
