import { Box, Paper, styled } from "@material-ui/core";
import theme from "../../theme";
import { DRAWER_MARGIN } from "../../theme/styleConstants";

export const Container = styled(({ ...props }) => (
  <Paper elevation={theme.elevation.background} {...props} />
))({
  height: "100vh",
  borderRadius: 0,
  marginRight: DRAWER_MARGIN,
  overflow: "auto",
});

export const Content = styled(({ ...props }) => <Box {...props} />)(
  ({ contentStyle, centralize }) => ({
    width: "100%",
    height: "90vh",
    
    ...contentStyle,
  })
);
