import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, withStyles, Tooltip } from "@material-ui/core";
import theme from "../../../../theme";
import { showAlertSaga } from '../../../../sagas/actions'
import { useDispatch } from "react-redux";

export const RedTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.error.main,
    fontSize: 12,
  },
}))(Tooltip);

const MessageBubble = ({
  messages,
  message,
  isChatBot,
  incoming,
  maxWidth = "70%",
  socket,
  position,
  type,
  color
}) => {
  const [errorCode, setErrorCode] = useState(0);
  const { aggregatedDeliveryReceipt } = message;
  const [isUndelivered, setIsUndelivered] = useState(
    aggregatedDeliveryReceipt?.state?.undelivered === "all"
  );
  const [isRead, setIsRead] = useState(
    aggregatedDeliveryReceipt?.state?.read === "all"
  );
  const dispatch = useDispatch();

  const getTooltipText = (code = errorCode) => {
    if (!code) return null;
    if (code == "63016" && !incoming)
      return "Falha ao enviar mensagem de formato livre. Use um Template.";
    else return "Falha ao enviar mensagem.";
  };

  const already = useRef(false);

  useEffect(() => {
    const typeSetting = async () => {
      const receipts = await message.getDetailedDeliveryReceipts();
      setErrorCode(receipts?.[0]?.errorCode);
    };
    typeSetting();
  }, []);

  useEffect(() => {
    socket.on("funcx", (data) => {
      if (data.EventType === "onDeliveryUpdated" && message.sid === data.MessageSid) {
        if (data.Status === 'undelivered') {
          setIsUndelivered(true);
          if (!incoming)
            dispatch(showAlertSaga({ type: 'error', message: getTooltipText(data?.ErrorCode) }));
        }
        setErrorCode(data?.ErrorCode);
        setIsRead(data.Status === "read");
      }
    });
  }, []);


  useEffect(() => {
    if (!already.current) {
      document.getElementById(message.sid).scrollIntoView({ behavior: "auto" });
      already.current = true;
    }
  }, [messages]);

  const containerStyle = {
    overflowWrap: "break-word",
    wordBreak: "break-all",
    whiteSpace: "pre-wrap",
    display: "flex",
    borderRadius: 7,
    padding: 4,
    marginTop: 4,
    width: "fit-content",
    maxWidth,
    color: isUndelivered ? theme.palette.error.main : "#000",
    backgroundColor: incoming || isChatBot ? color : (isUndelivered ? "#CCC" : "#F693A6"),
    marginRight: type !== "chat" && incoming ? "auto" : 0,
    marginLeft: type === 'chat' || !incoming ? 'auto' : 0,
  };

  return (
    <RedTooltip
      title={getTooltipText()}
      disableHoverListener={!getTooltipText() && !isUndelivered}
      placement={incoming ? "bottom-start" : "bottom-end"}
    >
      <Box display="flex" flexDirection="column">
        <Box style={containerStyle} id={message.sid}>
          <Typography>
            <Box fontWeight={600}>
              {type === "chat" && message.author + " "}
              {incoming
                ? type === "chat"
                  ? "(Atendente)"
                  : "(Contato)"
                : (isChatBot ? "Bot" : "Você")}
            </Box>
            <Box>{message.body}</Box>
            <Box>{message.state.timestamp.toLocaleTimeString("pt-BR")}</Box>
          </Typography>
        </Box>
        <Box marginLeft="auto">
          {isRead && !incoming && position === "LAST" ? (
            <Typography color="secondary">Visto</Typography>
          ) : null}
        </Box>
      </Box>
    </RedTooltip>
  );
};
export default MessageBubble;
