export const replaceInBrackets = (myString, replaceArray, replaceWith) => {
  if (!myString) return;
  if (!replaceArray?.length) return myString;
  for (var i = 0; i < replaceArray.length; i++) {
    myString = myString.replace(
      new RegExp("{" + replaceArray[i] + "}", "gi"),
      replaceWith[i]
    );
  }
  return myString;
};

export const mergeUnique = (a, b) => {
  if (!a || !b) return undefined;
  return a?.concat(b?.filter((v) => !a?.find((z) => z.id === v.id)));
};

export const getFirstPath = (path) => "/" + path?.split("/")[1];

const daysBetween = (date1, date2) => {
  const oneDay = 1000 * 60 * 60 * 24;
  const differenceMs = Math.abs(date1 - date2);
  return Math.round(differenceMs / oneDay);
};

const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

const isYesterday = (someDate) => {
  const diffDays = new Date().getDate() - someDate;
  const diffYears = new Date().getFullYear() - someDate.getFullYear();
  return diffYears === 0 && diffDays === 1;
};

export const formatDateToChat = (date, forConversationItem) => {
  const currentDate = new Date();
  if (isToday(date)) {
    if (forConversationItem) {
      return date.toLocaleTimeString('pt-BR', {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
    return "Hoje";
  }
  if (isYesterday(date)) return "Ontem";
  if (daysBetween(date, currentDate) < 7) {
    return date.toLocaleDateString('pt-BR', { weekday: "long" });
  }
  return date.toLocaleDateString('pt-BR');
};
