import React from "react";
import Form, { Widgets } from "@rjsf/material-ui";
import Password from "./Password";
import moment from "moment";
import Text from "./Text";

const DateTime = (props) => {
  return (
    <Widgets.DateTimeWidget
      {...props}
      inputProps={{ min: `${moment().format("YYYY-MM-DDTHH:MM")}` }}
      rawErrors={[]}
    />
  );
};

const widgets = {
  PasswordWidget: Password,
  DateTimeWidget: DateTime,
  TextWidget: Text,
};

const RJForm = ({
  formData,
  onChange,
  onSubmit,
  schema = {},
  uiSchema,
  showErrorList = false,
  liveValidate,
  disabled,
  validate,
  noValidate,
  ...rest
}) => {
  const filterKeys = (object) => {
    let result = {};
    for (let key in object) {
      if (!key.startsWith("_") && key !== "id") result[key] = object[key];
    }
    return result;
  };
  const errorMap = {
    required: "Este campo deve ser preenchido.",
    pattern: "Esta informação está fora de padrão.",
    maxLength: "Tamanho máximo excedido.",
    minLength: "Tamanho mínimo não alcançado.",
    type: "Tipo inválido.",
    format: "Formato inconsistente com o campo.",
  };
  function transformErrors(errors) {
    return errors.map((error) => {
      if (errorMap[error.name]) {
        error.message = errorMap[error.name];
      }
      return error;
    });
  }

  return (
    <Form
      transformErrors={transformErrors}
      disabled={disabled}
      liveValidate={liveValidate}
      showErrorList={showErrorList}
      onSubmit={onSubmit}
      onChange={onChange}
      formData={filterKeys(formData)}
      schema={schema}
      uiSchema={uiSchema}
      widgets={widgets}
      validate={validate}
      noValidate={noValidate}
      {...rest}
    />
  );
};

export default RJForm;
