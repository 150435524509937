import React from "react";
import theme from "../../theme";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";

export const availableHyperlinksToListItems = (
  availableHyperlinks,
  linkSetter
) => {
  if (!availableHyperlinks) return [];
  return Object.keys(availableHyperlinks)
    .filter(
      (item) =>
        ![
          "login",
          "self",
          "describedby",
          "next",
          "prev",
          "find",
          "search",
        ].includes(item)
    )
    .map((key) => ({
      title: availableHyperlinks[key].title,
      onClick: () => linkSetter(availableHyperlinks[key].href),
    }));
};

export const UpperButtons = ({
  onGoBack,
  onClickEdit,
  canEdit,
  onDelete,
  isTable,
  isRead,
  onClickCreate,
}) => {
  const { allowedOptionsHTTPHeader } = useSelector((state) => state.hateoas);
  return (
    <div
      style={{
        display: "flex",
        marginBottom: 10,
      }}
    >
      <Button style={{ marginRight: "auto" }} onClick={onGoBack}>
        Voltar
      </Button>
      {isTable && allowedOptionsHTTPHeader?.includes("POST") && (
        <Button onClick={() => onClickCreate()}>Criar</Button>
      )}
      {isRead && (
        <>
          {allowedOptionsHTTPHeader?.includes("PATCH") && (
            <Button
              onClick={() => onClickEdit()}
              style={{
                backgroundColor: canEdit ? "#00000011" : "transparent",
              }}
            >
              Editar
            </Button>
          )}
          {allowedOptionsHTTPHeader?.includes("DELETE") && (
            <Button
              style={{
                color: theme.palette.error.main,
                borderColor: theme.palette.error.main,
              }}
              onClick={onDelete}
            >
              Deletar
            </Button>
          )}
        </>
      )}
    </div>
  );
};
