import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import ConversationsMessages from "../ConversationsMessages";
import MessageBottomBar from "../MessageBottomBar";
import Headers from "../../../../components/Headers";
import { io } from "socket.io-client";
import { SOCKET_API } from "../../../../services/routes";

const PAGE_SIZE = 10;
let socket;
const Conversation = ({ proxy, myIdentity, style, mySid }) => {
  const [messages, setMessages] = useState([]);
  const [loadingState, setLoadingState] = useState("initializing");
  const [boundConversations, setBoundConversations] = useState([]);
  const [typing, setTyping] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const lastMessages = Array?.from(
    proxy?.messagesEntity?.messagesByIndex?.values()
  );

  const typingStarted = (participant) => {
    setTyping(true);
  };

  const typingEnded = (participant) => {
    setTyping(false);
  };

  const loadFirstMessages = () => {
    setLoadingState("ready");
    const sorted = lastMessages.sort((a, b) => b.state.index - a.state.index);
    setHasPrevPage(sorted.length > 15);
    if (sorted.length > 15) {
      return setMessages(
        [...sorted].filter((_, index) => index < 15).reverse()
      );
    } else {
      return setMessages([...sorted].reverse());
    }
  };

  const handleOnLoadMoreMessages = async (conversation) => {
    conversation
      .getMessages(PAGE_SIZE, messages?.[0]?.index)
      .then((messagePaginator) => {
        setHasPrevPage(messagePaginator.hasPrevPage);
        setMessages((prev) => messagePaginator.items.concat(prev.slice(1)));
        setLoadingState("ready");
      })
      .catch((err) => {
        console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
        setLoadingState("failed");
      });
  };

  useEffect(() => {
    socket = io(SOCKET_API);
    socket.emit("new-user", "user");
    return () => {
      socket.off();
    };
  }, []);

  useEffect(() => {
    setLoadingState("initializing");
  }, [proxy]);

  useEffect(() => {
    const messageAdded = async (message, targetConversation) => {
      if (targetConversation.sid === proxy.sid) {
        proxy.setAllMessagesRead().then(() => {
          setMessages((prev) => [...prev, message]);
        });
      }
    };

    const setAllRead = async (thisConversation) => {
      await thisConversation.setAllMessagesRead();
    };
    if (proxy) {
      loadFirstMessages();
      setAllRead(proxy);
      proxy.on("messageAdded", (m) => messageAdded(m, proxy));
      if (boundConversations.indexOf(proxy.sid) === -1) {
        proxy.on("typingStarted", (p) => typingStarted(p));
        proxy.on("typingEnded", typingEnded);
        setBoundConversations((prev) => [...prev, proxy.sid]);
      }
    }
  }, [proxy]);

  return (
    <Box style={style} display="flex" flexDirection="column">
      <Headers.Default title={proxy?.friendlyName} />
      <Box overflow="auto" height="80vh">
        <ConversationsMessages
          identity={myIdentity}
          messages={messages}
          loadingState={loadingState}
          hasPrevPage={hasPrevPage}
          socket={socket}
          proxy={proxy}
          onLoadMoreMessages={() => handleOnLoadMoreMessages(proxy)}
        />
      </Box>
      {typing && <Typography>Typing...</Typography>}
      <MessageBottomBar loadingState={loadingState} proxy={proxy} />
    </Box>
  );
};

export default Conversation;
