import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    backgroundColor: theme.palette.background.default,
    width: "90%",
    height: "200px",
    borderStyle: "solid",
    borderRadius: 10,
    margin: "auto",
    marginTop: 10,
    display: "flex",
    padding: 8,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    overflow: "clip",
  },
}));

export default useStyles;
