import {
  Box,
  Icon,
  IconButton,
  List,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import React from "react";
import Button from "../../../../components/Button";
import theme from "../../../../theme";

const DateTimesSelector = ({
  onChange,
  dateTimes,
  onAddDate,
  onRemoveDate,
}) => {
  return (
    <Box margin="auto" display="flex" height="100%" flexDirection="column">
      <List
        style={{
          overflow: "auto",
          height: "80%",
          display: "grid",
          flexDirection: "column",
          gridTemplateColumns: "50% 50%",
        }}
      >
        {dateTimes.map((item, index) => (
          <Box position="relative">
            <DateTimePicker
              style={{ margin: 4, width: 200 }}
              key={index + "_" + item.toISOString()}
              ampm={false}
              inputVariant="outlined"
              format="DD/MM/YYYY - HH:mm"
              value={item}
              onChange={(date) => onChange(moment(date), index)}
              autoOk
              disablePast
            />
            {dateTimes.length > 1 && (
              <IconButton
                onClick={() => onRemoveDate(index)}
                style={{
                  position: "absolute",
                  backgroundColor: "white",
                  border: "1px solid " + theme.palette.secondary.main,
                  right: 0,
                  width: 10,
                  height: 10,
                }}
              >
                <Icon color="secondary">close</Icon>
              </IconButton>
            )}
          </Box>
        ))}
      </List>
      <Button.AddButton onClick={onAddDate} />
    </Box>
  );
};

export default DateTimesSelector;
