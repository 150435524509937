import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import RJSForm from "../../components/RJSForm";
import { handleExportation, requestSaga } from "../../sagas/actions";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import "./index.css";
import { useHistory } from "react-router";

const FirstStage = ({ onClick1, onClick2 }) => (
  <>
    <DialogTitle>Selecione uma opção</DialogTitle>
    <Button
      color="primary"
      variant="outlined"
      style={{ margin: 10, marginTop: "auto" }}
      onClick={onClick1}
    >
      Importar Planilha de Contatos
    </Button>
    <Button
      color="primary"
      variant="outlined"
      style={{ margin: 10, marginTop: "auto" }}
      onClick={onClick2}
    >
      Novo Lead
    </Button>
  </>
);

const ImportFile = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [sheetData, setSheetData] = useState({ cols: [], rows: [] });
  const dispatch = useDispatch();
  const history = useHistory();
  const changeHandler = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  useEffect(() => {
    if (selectedFile) {
      ExcelRenderer(selectedFile, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          setSheetData(resp);
        }
      });
    }
  }, [selectedFile]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={1}
      justifyContent="center"
    >
      <input
        style={{ marginTop: 40, marginBottom: 40 }}
        type="file"
        name="file"
        onChange={changeHandler}
        accept=".xlsx"
      />
      {sheetData.rows.length > 0 && sheetData.cols.length > 0 && (
        <OutTable
          data={sheetData.rows}
          columns={sheetData.cols}
          tableClassName="ExcelTable2007"
          tableHeaderRowClass="heading"
        />
      )}
      <Button
        color="primary"
        disabled={!selectedFile}
        variant="contained"
        style={{ marginTop: "auto" }}
        onClick={() => dispatch(handleExportation([selectedFile], history))}
      >
        Enviar
      </Button>
    </Box>
  );
};

const SecondStage = ({ onClick, onSend, schema, contactsLink }) => {
  const [formState, setFormState] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <RJSForm
        onSubmit={() => {}}
        formData={formState}
        onChange={({ formData }) =>
          setFormState((prev) => ({ ...prev, ...formData }))
        }
        liveValidate
        schema={schema}
      >
        <Button
          style={{ display: "flex", marginLeft: "auto" }}
          type="submit"
          variant="contained"
          color="secondary"
          onClick={() => {
            dispatch(
              requestSaga(
                "post",
                contactsLink,
                formState,
                () => {
                  onSend();
                  history.push("/atender");
                },
                () => {},
                history
              )
            );
          }}
        >
          Criar
        </Button>
      </RJSForm>
    </>
  );
};

const NewConversationDialog = ({
  newConversationOpen,
  onClose,
  schema,
  contactsLink,
}) => {
  const [stage, setStage] = useState(1);
  const [sampleOpen, setSampleOpen] = useState(false);
  const sheetData = {
    rows: [
      ["nome", "numero", "email", "avatar"],
      ["Caio", 85999999999, "caio@gmail.com", "link.com"],
      ["Vinícius", 85999999999, "vini@gmail.com", "link.com"],
      ["Gabriel", 85999999999, "gabriel@gmail.com", "link.com"],
    ],
    cols: [
      { name: "A", key: 0 },
      { name: "B", key: 1 },
      { name: "C", key: 2 },
      { name: "D", key: 3 },
    ],
  };
  return (
    <Dialog open={newConversationOpen} onClose={onClose}>
      <DialogContent style={{ display: "flex" }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" justifyContent="space-between" width={"100%"}>
            {stage !== 1 && <Button onClick={() => setStage(1)}>Voltar</Button>}
            {stage === 2 && (
              <Button onClick={() => setSampleOpen(true)}>?</Button>
            )}
          </Box>
          {stage === 1 && (
            <FirstStage
              onClick1={() => setStage(2)}
              onClick2={() => setStage(3)}
            />
          )}
          {stage === 2 && <ImportFile />}
          {stage === 3 && (
            <SecondStage
              onSend={onClose}
              schema={schema}
              contactsLink={contactsLink}
            />
          )}
        </Box>
      </DialogContent>
      {sampleOpen && (
        <Dialog open={sampleOpen} onClose={() => setSampleOpen(false)}>
          <DialogContent style={{ display: "flex", flexDirection: "column" }}>
            <Button
              style={{ marginLeft: "auto" }}
              onClick={() => setSampleOpen(false)}
            >
              x
            </Button>
            <Divider />
            <Typography>
              Este é um exemplo de como deve ser o formato da planilha com os
              dados dos leads:
            </Typography>
            <Box margin="auto">
              <OutTable
                data={sheetData.rows}
                columns={sheetData.cols}
                tableClassName="ExcelTable2007"
                tableHeaderRowClass="heading"
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Dialog>
  );
};

export default NewConversationDialog;
