import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  schema: {},
  hyperlink: {},
  fullResource: null,
  availableHyperlinks: [],
  availableEmbedded: null,
  loading: false,
  allowedOptionsHTTPHeader: "",
};

export const getters = {
  paginated: (state) => (state?.fullResource?.pages ? true : false),
  pageContent: (state) =>
    state?.availableEmbedded
      ? state.availableEmbedded[
          state.availableHyperlinks?.self?.href
            .split("/")
            .slice(-1)[0]
            .split("?")[0]
        ]
      : undefined,
  nextPageHyperlink: (state) => state?.availableHyperlinks?.next,
  previousPageHyperlink: (state) => state?.availableHyperlinks?.prev,
  findPageHyperlink: (state) => state?.availableHyperlinks?.find,
  totalOfResourcesInCollection: (state) => state.fullResource?.total,
  indexOfCurrentPageInCollection: (state) => state.fullResource?.currentPage,
  totalOfPagesInCollection: (state) => state.fullResource?.pages,
  canGET: (state) => state?.allowedOptionsHTTPHeader.includes("GET"),
  canPOST: (state) => state?.allowedOptionsHTTPHeader.includes("POST"),
  canDELETE: (state) => state?.allowedOptionsHTTPHeader.includes("DELETE"),
  canPATCH: (state) => state?.allowedOptionsHTTPHeader.includes("PATCH"),
};

export const hateoasSlice = createSlice({
  name: "hateoas",
  initialState: INITIAL_STATE,
  reducers: {
    setHateoasLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    updateHateoas: (
      state,
      {
        payload: {
          schema,
          hyperlink,
          availableHyperlinks,
          availableEmbedded,
          fullResource,
          allowedOptionsHTTPHeader,
        },
      }
    ) => {
      state.hyperlink = hyperlink ? hyperlink : null;
      state.availableHyperlinks = availableHyperlinks
        ? availableHyperlinks
        : [];
      state.schema = schema ? schema : undefined;
      state.availableEmbedded = availableEmbedded;
      state.fullResource = fullResource ? fullResource : null;
      state.allowedOptionsHTTPHeader = allowedOptionsHTTPHeader
        ? allowedOptionsHTTPHeader
        : "";
    },
    updateResource(state, { payload: { newData, onlyBody } }) {
      if (onlyBody) {
        state.fullResource = {
          _links: { ...state.availableHyperlinks },
          _embedded: { ...state.availableEmbedded },
          ...newData,
        };
      } else {
        state.fullResource = newData;
      }
    },
    resetHateoas(state) {
      state.schema = {};
      state.hyperlink = {};
      state.fullResource = null;
      state.availableHyperlinks = [];
      state.availableEmbedded = null;
      state.loading = false;
      state.allowedOptionsHTTPHeader = "";
    },
  },
});

export const {
  setHateoasLoading,
  updateHateoas,
  resetHateoas,
  updateResource,
} = hateoasSlice.actions;

export default hateoasSlice.reducer;
