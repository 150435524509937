const OPEN_CHAR = "{{";
const CLOSE_CHAR = "}}";
export const specialFields = ["nome"];
export const splitTemplate = (template = []) => {
  let aux = "";
  let array = [];
  const len = template.length;
  const skip = OPEN_CHAR.length - 1;
  for (let i = 0; i < len; i++) {
    const firstTwo = template[i] + template[i + 1]; // GENERALIZAR.
    if (firstTwo === OPEN_CHAR) {
      array.push(aux);
      aux = OPEN_CHAR;
      i += skip;
    } else if (firstTwo === CLOSE_CHAR) {
      aux = aux + CLOSE_CHAR;
      array.push(aux);
      aux = "";
      i += skip;
    } else aux += template[i];
  }
  array.push(aux);
  return array;
};

export const joinTemplate = (
  template = "{name}",
  items = { name: "John Lennon" }
) => {
  let joined = template;
  const keys = Object.keys(items);
  keys.forEach((key) => {
    joined = joined.replace(OPEN_CHAR + key + CLOSE_CHAR, items[key]);
  });
  return joined;
};

export const isLabel = (string) =>
  string.startsWith(OPEN_CHAR) && string.endsWith(CLOSE_CHAR);

export const getLabels = (template) =>
  splitTemplate(template)
    .filter((item) => isLabel(item))
    .map((item) =>
      item.slice(OPEN_CHAR.length, item.length - CLOSE_CHAR.length)
    );

export const getInitialState = (template, includeSpecial) =>
  Object.fromEntries(
    getLabels(template).map((k) => [
      k,
      specialFields.includes(k) && includeSpecial ? `{{${k}}}` : "",
    ])
  );
