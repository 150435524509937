import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  SAGA_TYPES,
  showAlertForHTTPResponseAsync,
  handleGeneralErrorAsync as HGEA,
} from "./actions";
import httpClient from "../services/rest";
import { setRequestData } from "../store/uiSlice";
import { logout } from "../store";
import { request_status } from "./requestStatus";

const toLogoutErrors = [401, 422];
const hasToLogout = (e) => toLogoutErrors.includes(e?.response?.status);

// TODO: É onde fazemos os requests
export function* requestSaga({
  payload: { href, data, method, onSuccess, onError, history, showAlert, alertType },
}) {
  let tmp;
  try {
    let success = false;
    yield put(
      setRequestData({ type: method, status: request_status.requesting })
    );
    try {
      tmp = yield call(httpClient.request, { method, url: href, data });
      success = true;
      onSuccess(tmp);
    } catch (e) {
      yield put(HGEA(e, history));
      tmp = e.response;
      success = false;
      onError(e);
    }
    yield put(
      setRequestData({
        type: method,
        status: success ? request_status.success : request_status.error,
      })
    );
  } catch (e) {
    console.log(e);
  }
  if (showAlert) {
    yield put(showAlertForHTTPResponseAsync(tmp, alertType));
  }
}

export function* handleGeneralErrorAsync({ payload: { error, history } }) {
  yield put(showAlertForHTTPResponseAsync(error?.response, "authorization"));
  if (hasToLogout(error)) {
    yield put(logout());
  } else if (error?.response?.status === 403) {
    if (history) history?.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(SAGA_TYPES.REQUEST, requestSaga),
    takeEvery(SAGA_TYPES.HANDLE_GENERAL_ERROR, handleGeneralErrorAsync),
  ]);
}
