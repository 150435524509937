import { all, call, put, takeEvery } from "redux-saga/effects";
import { setHateoasLoading, updateHateoas } from "../store/hateoasSlice";
import {
  SAGA_TYPES,
  showAlertForHTTPResponseAsync,
  handleGeneralErrorAsync,
} from "./actions";
import httpClient from "../services/rest";
import { setDisplayedTitle } from "../store/uiSlice";

export function* handleHyperlinkUpdateAsync({ payload }) {
  try {
    yield put(setHateoasLoading({ loading: true }));
    // carga que será disponibilizada no Store, inicialmente vazia
    let hateoasUpdatePayload = {};
    // recipiente para a request ao link sucessor
    let successorStep;
    let schemaStep;
    // recipiente para o resultado do método OPTIONS
    let allowedOptionsHTTPHeader;
    if (payload?.successorLink) {
      // a carga possui o link sucessor
      hateoasUpdatePayload.hyperlink = payload?.successorLink;

      // o link sucessor deve ser acessado, a fim de obter os links disponíveis partindo dele
      successorStep = yield call(httpClient.get, payload?.successorLink?.href);

      let tmp = yield call(httpClient.options, payload?.successorLink?.href);
      allowedOptionsHTTPHeader = tmp?.headers?.["allow"];
    } else {
      // caso não haja link sucessor, o link raiz deve sucedê-lo
      // hateoasUpdatePayload.hyperlink =
      successorStep = yield call(httpClient.get);
    }

    // carrega o resource do link sucessor
    if (successorStep) {
      hateoasUpdatePayload.fullResource = successorStep.data;
    }

    // carrega as OPTIONS do link sucessor
    if (allowedOptionsHTTPHeader) {
      hateoasUpdatePayload.allowedOptionsHTTPHeader = allowedOptionsHTTPHeader;
    }

    // se o sucessor leva a novos links, eles devem ser armazenados na carga
    if (successorStep?.data?._links) {
      // os links disponíveis devem ser postos na carga
      hateoasUpdatePayload.availableHyperlinks = successorStep.data._links;
    }

    // Se houver embutidos, adiciona-os na carga
    if (successorStep?.data?._embedded) {
      hateoasUpdatePayload.availableEmbedded = successorStep?.data?._embedded;
    }

    // recipiente para o link describedby, quando existir
    let linkFordescribedby = successorStep?.data?._links?.describedby;

    // se houver link describedby, ele deve ser acessado, a fim de obter o JSON Schema que descreve o link sucessor
    if (linkFordescribedby) {
      schemaStep = yield call(httpClient.get, linkFordescribedby.href);
      let schema = schemaStep.data;
      // Se for obtido JSON Schema, ele deve ser armazenado na carga
      if (schema) {
        hateoasUpdatePayload.schema = schema;
      }
    }

    // Por fim, a carga deve ser commitada com a mutation correta
    yield put(updateHateoas(hateoasUpdatePayload));
    yield put(setHateoasLoading({ loading: false }));

    // E atualiza o título do site
    const title = hateoasUpdatePayload?.availableHyperlinks?.self?.title;
    yield put(
      setDisplayedTitle({
        displayedTitle: payload?.successorLink?.title
          ? payload?.successorLink?.title
          : title || "Bem-vindo!",
      })
    );
  } catch (e) {
    yield put(setHateoasLoading({ loading: false }));
    yield put(handleGeneralErrorAsync(e, payload?.history));
  }
}

export function* handleExportationAsync({ payload: { files, history } }) {
  yield put(setHateoasLoading({ loading: true }));
  const rootResponse = yield call(httpClient.get, "/");
  const contactsLink = rootResponse.data._links.contacts.href;
  if (files) {
    let formData = new FormData();
    let importStep;
    files.forEach((file, i) => formData.append("files[" + i + "]", file));
    try {
      importStep = yield call(httpClient.post, contactsLink, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // if (importStep.status === 205) {
      //   put(HYUA(state.hyperlink.HREF));
      // }
      yield put(showAlertForHTTPResponseAsync(importStep));
    } catch (e) {
      yield put(handleGeneralErrorAsync(e, history));
    }
  }
  yield put(setHateoasLoading({ loading: false }));
}

export default function* rootSaga() {
  yield all([
    takeEvery(SAGA_TYPES.HANDLE_HYPERLINK_UPDATE, handleHyperlinkUpdateAsync),
    takeEvery(SAGA_TYPES.HANDLE_EXPORTATION, handleExportationAsync),
  ]);
}
