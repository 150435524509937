import { Widgets } from "@rjsf/material-ui";
import React from "react";

const Phone = (props) => {

  function telefone(v = "") {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d\d)(\d)/g, "($1) $2");
    v = v.replace(/(\d{5})(\d)/, "$1-$2");
    return v;
  }

  return (
    <Widgets.TextWidget
      {...props}
      onChange={(v) => props.onChange(telefone(v))}
    />
  );
};

export default Phone;
