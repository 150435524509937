import { ButtonBase, styled } from "@material-ui/core";

export const CircularStyled = styled(({ ...props }) => (
  <ButtonBase {...props} />
))(({ backgroundColor, size, shadow, containerStyle }) => ({
  backgroundColor: backgroundColor,
  borderRadius: size,
  width: size,
  height: size,
  boxShadow: shadow
    ? "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)"
    : "none",
  ...containerStyle,
}));
