import {
  Box,
  ButtonBase,
  CircularProgress,
  Icon,
  IconButton,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";
import useTemplate from "../../hooks/useTemplate";
import useStyles from "./styles";

const TemplateList = ({
  onClickTemplate,
  height = "50vh",
  gridTemplateColumns = "50% 50%",
  bgcolor,
  width = 1,
}) => {
  const [searchText, setSearchText] = useState("");
  const [textToRequest, setTextToRequest] = useState("");
  const [page, setPage] = useState(1);

  const { templates, loading, total } = useTemplate(page, 20, textToRequest);
  const classes = useStyles();

  const handleChangeSearchText = ({ target: { value } }) => {
    setSearchText(value);
  };

  const onLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  const initiatorColor = (is) =>
    is ? "0px 4px 5px #f1bd40" : "0px 4px 5px rgba(0, 0, 0, 0.14)";

  return (
    <Box
      bgcolor={bgcolor}
      height={height}
      display="flex"
      flexDirection="column"
      overflow="auto"
      width={width}
    >
      <Box display="flex" flexDirection="row">
        <TextField
          label="Pesquisar pelo título"
          type="search"
          variant="outlined"
          value={searchText}
          onChange={handleChangeSearchText}
          style={{ width: "90%", margin: 10, alignSelf: "center" }}
        />
        <IconButton
          onClick={() => setTextToRequest(searchText)}
          style={{ width: 60, height: 60, alignSelf: "center", border: '1px solid gray' }}
        >
          <Icon>search</Icon>
        </IconButton>
      </Box>
      {loading && <CircularProgress style={{ alignSelf: "center" }} />}
      <Box display="grid" gridTemplateColumns={gridTemplateColumns}>
        {!loading && templates.length === 0 && (
          <Typography>Não há templates disponíveis.</Typography>
        )}
        {templates.map((item) => (
          <ButtonBase
            onClick={() => onClickTemplate(item)}
            style={{
              boxShadow: initiatorColor(item.canStartConversation),
              border: "1px solid " + initiatorColor(item.canStartConversation),
            }}
            classes={{ root: classes.itemContainer }}
          >
            <Typography>
              {item.canStartConversation ? "INICIADOR DE CONVERSAS" : " "}
            </Typography>
            <Typography align="left" variant="h6">
              {item.title.toUpperCase()}
            </Typography>
            <Typography align="left">{item.body}</Typography>
          </ButtonBase>
        ))}
      </Box>
      {total > templates.length && !loading && (
        <Tooltip title={"Mostrar mais templates"} placement="top">
          <IconButton style={{ alignSelf: "center" }} onClick={onLoadMore}>
            <Icon>more_horiz</Icon>
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default TemplateList;
