import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Widgets } from "@rjsf/material-ui";
import React, { useState } from "react";

const Password = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Widgets.TextWidget
        {...props}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default Password;
