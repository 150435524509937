import { useEffect, useState } from "react";
import httpClient from "../services/rest";
import { useDispatch } from "react-redux";
import { handleGeneralErrorAsync } from "../sagas/actions";
import { replaceInBrackets } from "../utils";

const useSegmentations = (page = 1, itemsPerPage = 5, searchText = "") => {
  const [segmentations, setSegmentations] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setLoading(true);
  }, [page, itemsPerPage, searchText]);

  useEffect(() => {
    const fetching = async () => {
      try {
        const rootResponse = await httpClient.get("/");
        const segmentationLink = rootResponse.data._links?.segmentations?.href;
        if (!segmentationLink) {
          return setSegmentations([]);
        }
        const response = await httpClient.get(segmentationLink);
        const findLink = response.data._links.find.href;
        const findLinkWithQuery =
          replaceInBrackets(
            findLink,
            ["page", "resourcesPerPage"],
            [page, itemsPerPage]
          ) +
          "&q=" +
          searchText;
        const queryContacts = await httpClient.get(findLinkWithQuery);
        setTotal(queryContacts.data.total);
        const subList = queryContacts.data._embedded.segmentation;
        setSegmentations((prev) =>
          page === 1 ? subList : prev.concat(subList)
        );
      } catch (e) {
        dispatch(handleGeneralErrorAsync(e));
      }
      setLoading(false);
    };
    if (loading) {
      fetching();
    }
  }, [loading]);

  return { segmentations, loading, total };
};

export default useSegmentations;
