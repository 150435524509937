import React, { Fragment } from "react";
import MessageBubble from "../MessageBubble";
import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  List,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import theme from "../../../../theme";
import { formatDateToChat } from "../../../../utils";
let date = new Date();

const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

const ConversationsMessages = ({
  messages,
  identity,
  onLoadMoreMessages,
  loadingState,
  hasPrevPage,
  socket,
  proxy,
}) => {
  const green = "#c6e5b1";
  const blue = "#38a2d3";
  const colors = ["#FFEA97", "#E8C8CE", "#EDF4C7", "#DAFDF6", "#F693A6"];
  const participantsDetails = {};
  let i = 0;
  Array.from(proxy.participants.values()).forEach((item) => {
    if (item.state.type !== "whatsapp" && item.state.identity !== identity) {
      participantsDetails[item.state.identity] = {
        color: colors[i],
        type: item.state.type,
      };
      if (i < colors.length - 1) {
        i++;
      }
    }
  });
  return (
    <Box id="messages" padding={1} flexDirection="column" display="flex">
      {hasPrevPage && loadingState === "ready" && (
        <IconButton
          onClick={onLoadMoreMessages}
          style={{
            borderWidth: 1,
            borderColor: "black",
            border: `1px solid ${theme.palette.secondary.main}`,
            alignSelf: "center",
          }}
        >
          <Icon color="secondary">add</Icon>
        </IconButton>
      )}
      {loadingState === "initializing" && (
        <CircularProgress style={{ alignSelf: "center", marginTop: "auto" }} />
      )}
      {loadingState === "ready" && (
        <List>
          {messages.map((m, index) => {
            let isChatBot = false;
            let addDate = false;
            if (!datesAreOnSameDay(date, m.dateCreated)) {
              addDate = true;
            }
            let position = "";
            if (index === messages.length - 1) {
              position = "LAST";
            }
            date = m.dateCreated;
            if(!m.state.participantSid)  {
              isChatBot = true;
            }
            return (
              <Fragment key={m.index}>
                {(addDate || index === 0) && (
                  <ListSubheader
                    style={{
                      backgroundColor: "#00000000",
                      alignItems: "center",
                      display: "flex",
                      margin: 10,
                    }}
                  >
                    <Typography
                      style={{
                        alignSelf: "center",
                        margin: "auto",
                        backgroundColor: theme.palette.primary.light,
                        borderRadius: 100,
                        padding: 5,
                        width: 150,
                        textAlign: "center",
                      }}
                      id={m.dateCreated.toLocaleDateString()}
                    >
                      {formatDateToChat(m.dateCreated)}
                    </Typography>
                  </ListSubheader>
                )}
                <MessageBubble
                  type={
                    participantsDetails[m.author]
                      ? participantsDetails[m.author].type
                      : "whatsapp"
                  }
                  color={
                    isChatBot ? blue : (participantsDetails[m.author]
                      ? participantsDetails[m.author].color
                      :  green)
                  }
                  messages={messages}
                  isChatBot={isChatBot}
                  incoming={m.author !== identity && !isChatBot}
                  message={m}
                  socket={socket}
                  position={position}
                />
              </Fragment>
            );
          })}
        </List>
      )}
    </Box>
  );
};

export default ConversationsMessages;
