import { createTheme } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/data-grid";
import { ptBR as corePtBr } from "@material-ui/core/locale";


const primary = {
  main: "#f4d548",
  dark: "#eda737",
  contrastText: "#000",
  900: "#e68129",
  800: "#eda737",
  700: "#f1bd40",
  600: "#f4d548",
  500: "#f4e44b",
  400: "#f6e964",
  300: "#f9ee7f",
  200: "#faf4a2",
  100: "#fcf8c7",
  "050": "#fefce8",
};

const secondary = {
  main: "#e63953",
  dark: "#881f44",
  contrastText: "#000",
  900: "#881f44",
  800: "#ac284b",
  700: "#c02e4d",
  600: "#d63451",
  500: "#e63953",
  400: "#eb4d6a",
  300: "#f16984",
  200: "#f693a6",
  100: "#fabdc9",
  "050": "#fde5e9",
};

export default createTheme({
  palette: {
    primary,
    secondary,
    background: {
      default: "#FEFCE8",
      paper: "#FEFCE8",
      contrastText: "#000",
    },
    error: {
      main: "#b00020",
      contrastText: "#fff",
    },
  },

  typography: {
    fontFamily: "rubik",
    h1: {
      fontSize: "96px",
      fontWeight: "lighter",
    },
    h2: {
      fontSize: "60px",
      fontWeight: "lighter",
    },
    h3: {
      fontSize: "48px",
      fontWeight: "normal",
    },
    h4: {
      fontSize: "34px",
      fontWeight: "normal",
    },
    h5: {
      fontSize: "24px",
      fontWeight: "normal",
    },
    h6: {
      fontSize: "20px",
      fontWeight: "bold",
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: "normal",
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "16px",
      fontWeight: "normal",
    },
    body2: {
      fontSize: "14px",
      fontWeight: "normal",
    },
    button: {
      textTransform: "uppercase",
      fontSize: "14px",
      fontWeight: "bold",
    },
    caption: {
      fontSize: "12px",
      fontWeight: "normal",
    },
    overline: {
      fontSize: "10px",
      fontWeight: "normal",
    },
  },
  elevation: {
    background: 5,
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
  },
  zIndex: { drawer: 1000 },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: {
          palette: {},
        },
      },
    },
  },
  corePtBr,
  ptBR,
});
