import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  isLogged: false,
  info: {
    twilioAccessToken: '',
    twilioIdentity: '',
    user: {},
  },
  status: "",
};

export const LOGIN_STATUS = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    login: (state, action) => {
      state.info.user = action.payload.user;
      state.info.twilioAccessToken = action.payload.twilioAccessToken;
      state.info.twilioIdentity = action.payload.twilioIdentity;
      state.status = LOGIN_STATUS.SUCCESS;
      state.isLogged = true;
    },
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
  },
});

export const userSelector = (state) => state.user;

export const { login, setStatus } = userSlice.actions;

export default userSlice.reducer;
