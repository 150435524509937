import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import theme from "../../../../theme";
import {
  getInitialState,
  isLabel,
  joinTemplate,
  specialFields,
  splitTemplate,
} from "../../../../utils/templateUtils";

const FillTemplate = ({
  templateBody = "",
  onChange,
  inputStyle = {},
  textStyle = {},
  containerStyle = {},
  isDirect,
}) => {
  const splitted = splitTemplate(templateBody);
  const [inputs, setInputs] = useState(getInitialState(templateBody, !isDirect));

  const handleOnChangeInput = (event) => {
    setInputs((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    onChange(joinTemplate(templateBody, inputs));
  }, [inputs, onChange, templateBody]);

  return (
    <div style={containerStyle}>
      {splitted.map((item, index) => {
        if (isLabel(item)) {
          const label = item.slice(2, item.length - 2);
          if (specialFields.includes(label.toLowerCase()) && !isDirect)
            return (
              <Tooltip
                title={
                  "Este campo será preenchido automaticamente pelo sistema."
                }
                placement="bottom-end"
              >
                <input
                  style={{
                    width: 80,
                    border: `1px solid ${theme.palette.primary.main}`,
                    outline: "none",
                    borderRadius: 5,
                    ...inputStyle,
                  }}
                  disabled
                  key={index}
                  name={label}
                  placeholder={label}
                />
              </Tooltip>
            );
          return (
            <input
              style={{
                width: 80,
                border: `1px solid ${
                  !inputs?.[label]?.length
                    ? theme.palette.secondary.main
                    : "#ccc"
                }`,
                outline: "none",
                borderRadius: 5,
                ...inputStyle,
              }}
              key={index}
              name={label}
              placeholder={label}
              onChange={handleOnChangeInput}
              value={inputs[label]}
            />
          );
        }
        return <span style={{ ...textStyle }}>{item}</span>;
      })}
    </div>
  );
};

export default FillTemplate;
