import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: ({ style }) => ({
    position: "relative",
    width: "100%",
    height: "100%",
    ...style,
  }),
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    width: "100%",
    backgroundColor: "#00000011",
  },
}));

export default useStyles;
