import RJSForm from "../../../components/RJSForm";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestSaga } from "../../../sagas/actions";
import { Box, Button } from "@material-ui/core";
import { UpperButtons } from "../helpers";
import { useHistory } from "react-router";

const Form = ({ isCreation, onGoBack, isRead }) => {
  const { schema, fullResource, availableHyperlinks } = useSelector(
    (state) => state.hateoas
  );
  const selfLink = availableHyperlinks.self.href;
  const firstState = (fr) => {
    const newForm = { ...fr };
    if ("email" in fr && fr.email === "") {
      newForm.email = undefined;
      return newForm;
    }
    return fr;
  };
  const [canEdit, setCanEdit] = useState(isCreation && true);
  const [formState, setFormState] = useState(firstState(fullResource));
  const dispatch = useDispatch();
  const history = useHistory();

  const handlePost = async () => {
    dispatch(
      requestSaga(
        "post",
        selfLink,
        formState,
        () => {},
        () => {},
        history
      )
    );
    onGoBack();
  };

  const handlePatch = async () => {
    dispatch(
      requestSaga(
        "patch",
        selfLink,
        formState,
        () => {},
        () => {},
        history
      )
    );
    setCanEdit(false);
    onGoBack();
  };

  const handleDelete = async () => {
    dispatch(
      requestSaga(
        "delete",
        selfLink,
        {},
        () => {},
        () => {},
        history
      )
    );
    onGoBack();
  };

  const handleSubmitForm = async () => {
    if (!isCreation) {
      return handlePatch();
    }
    handlePost();
  };

  return (
    <Box>
      <Box padding={1} display="flex" flexDirection="column">
        <UpperButtons
          isRead={isRead}
          onGoBack={onGoBack}
          canEdit={canEdit}
          onClickEdit={() => setCanEdit((prev) => !prev)}
          onDelete={handleDelete}
        />
        {
          <RJSForm
            onSubmit={handleSubmitForm}
            formData={formState}
            onChange={({ formData }) => {
              setFormState((prev) => ({ ...prev, ...formData }));
            }}
            disabled={!canEdit}
            liveValidate
            schema={schema || {}}
          >
            {canEdit ? (
              <Button
                style={{ display: "flex", marginLeft: "auto" }}
                type="submit"
                variant="contained"
                color="secondary"
              >
                {isCreation ? "Criar" : "Enviar"}
              </Button>
            ) : (
              <> </>
            )}
          </RJSForm>
        }
      </Box>
    </Box>
  );
};

export default Form;
