import React, { useEffect, useState } from "react";
import {
  List,
  Box,
  makeStyles,
  ListSubheader,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Headers from "../../../../components/Headers";
import ConversationsListItem from "../ConversationsListItem";
import httpClient from "../../../../services/rest";
import theme from "../../../../theme";
import { myAtendSections, atendSections, segSections } from "./filters";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  formControl: {
    width: "95%",
    alignSlef: "center",
    margin: "auto",
    marginBottom: 10,
    marginTop: 10,
  },
}));

const ConversationsList = ({
  conversations,
  selectedConversationSid,
  onConversationClick,
  style,
  identity,
}) => {
  const classes = useStyles();
  const [conversationsList, setConversationsList] = useState([]);
  const [segmentations, setSegmentations] = useState([]);
  const [usedSectionSchema, setUsedSectionSchema] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setConversationsList(
      conversations.sort(
        (a, b) =>
          (b?.lastMessage?.dateCreated ?? 0) -
          (a?.lastMessage?.dateCreated ?? 0)
      )
    );
  }, [conversations]);

  useEffect(() => {
    const fetch = async () => {
      const response = await httpClient.get("/");
      const segmentationsLink = response?.data?._links?.segmentations?.href;
      const response2 = await httpClient.get(
        segmentationsLink + "?resourcesPerPage=500"
      );
      const list = response2?.data?._embedded?.segmentation;
      setSegmentations(list);
    };
    fetch();
  }, []);

  return (
    <Box style={style} overflow="auto">
      <Headers.Default
        title="Leads"
        rightButtonProps={{
          onClick: () => setDialogOpen((prev) => !prev),
          iconName: "filter_list",
          size: 20,
        }}
      />
      <Collapse in={dialogOpen} collapsedSize={0}>
        <Box display="flex">
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel id="demo-simple-select-filled-label">
              Tipo de organização
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={usedSectionSchema}
              onChange={({ target: { value } }) => setUsedSectionSchema(value)}
            >
              <MenuItem value="">Sem Filtro</MenuItem>
              <MenuItem value={atendSections()}>Por Atendimento</MenuItem>
              <MenuItem value={segSections(segmentations)}>
                Por Segmentos
              </MenuItem>
              <MenuItem value={myAtendSections(identity)}>
                Meus Atendimentos
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Collapse>
      <List subheader={<li />}>
        {usedSectionSchema
          ? usedSectionSchema.map(({ title, filterFunc }) => (
              <li className={classes.listSection}>
                <ul className={classes.ul}>
                  <ListSubheader
                    style={{
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    {title}
                  </ListSubheader>
                  {conversationsList.filter(filterFunc).map((item) => (
                    <ConversationsListItem
                      key={item.sid}
                      identity={identity}
                      conversations={conversations}
                      item={item}
                      onConversationClick={onConversationClick}
                      selectedConversationSid={selectedConversationSid}
                    />
                  ))}
                </ul>
              </li>
            ))
          : conversationsList.map((item) => (
              <ConversationsListItem
                key={item.sid}
                identity={identity}
                conversations={conversations}
                item={item}
                onConversationClick={onConversationClick}
                selectedConversationSid={selectedConversationSid}
              />
            ))}
      </List>
    </Box>
  );
};

export default ConversationsList;
