import _traverson from "traverson";
import JsonHalAdapter from "traverson-hal";
// import Store from "../../store/index"

_traverson.registerMediaType(JsonHalAdapter.mediaType, JsonHalAdapter);

const traverson = _traverson;
// const traverson = _traverson.from('http://127.0.0.1:5000/').jsonHal().withRequestOptions({
//     headers: {
//         'Authorization' : `Bearer ${Store.state.accessToken}`
//     }
// })

export default traverson;
