import { useEffect, useState } from "react";
import httpClient from "../services/rest";
import { useDispatch } from "react-redux";
import { handleGeneralErrorAsync } from "../sagas/actions";
import { replaceInBrackets } from "../utils";

const useTemplate = (page = 1, itemsPerPage = 5, searchText = "") => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
  }, [page, itemsPerPage, searchText]);

  useEffect(() => {
    const fetching = async () => {
      try {
        const rootResponse = await httpClient.get("/");
        const templateLink = rootResponse?.data?._links?.template?.href;
        if (!templateLink) {
          return setTemplates([]);
        }
        const response = await httpClient.get(templateLink);
        const findLink = response.data._links.find.href;
        const findLinkWithQuery =
          replaceInBrackets(
            findLink,
            ["page", "resourcesPerPage"],
            [page, itemsPerPage]
          ) +
          "&q=" +
          searchText;
        const queryTemplates = await httpClient.get(findLinkWithQuery);
        setTotal(queryTemplates.data.total);
        const subList = queryTemplates.data._embedded.template;
        setTemplates((prev) => (page === 1 ? subList : prev.concat(subList)));
      } catch (e) {
        dispatch(handleGeneralErrorAsync(e));
      }
      setLoading(false);
    };
    if (loading) {
      fetching();
    }
  }, [loading]);

  return { templates, loading, total };
};

export default useTemplate;
