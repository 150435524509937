import React from "react";
import "./App.css";
import { Collapse, ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import Navigation from "./navigation";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { BACKGROUND_COLOR } from "./theme/styleConstants";
import { Alert } from "@material-ui/lab";
import { alertDataSelector } from "./store/uiSlice";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("br");

function App() {
  const { displayedTitle } = useSelector((state) => state.ui);
  const { message, type, show } = useSelector(alertDataSelector);

  return (
    <div style={{ backgroundColor: BACKGROUND_COLOR }}>
      <Helmet title={displayedTitle}>
        <meta
          name="title"
          content={displayedTitle}
          data-react-helmet="true"
        ></meta>
      </Helmet>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          libInstance={moment}
          locale="pt"
        >
          <Navigation />
          <Collapse
            in={show}
            style={{ position: "absolute", bottom: 10, right: 10 }}
          >
            <Alert variant="filled" severity={type || "warning"}>
              {message}
            </Alert>
          </Collapse>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
