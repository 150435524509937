import React from "react";
import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Box, Grid } from "@material-ui/core";

const LoginButton = styled(({ ...props }) => (
  <Button type="submit" color="secondary" variant="contained" {...props} disabled={props.loading} />
))({
  width: 100,
  marginTop: 20,
  margin: "auto",
  display: "flex",
});

const Container = styled(({ ...props }) => <Grid container {...props} />)(
  ({ theme }) => ({
    height: "100vh",
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
  })
);

const Wrapper = styled(({ ...props }) => {
  return (
    <Box
      display="flex"
      alignItems="flex-end"
      justifyContent="center"
      flexDirection="column"
      {...props}
    />
  );
})(({ width }) => ({ width }));

export { LoginButton, Container, Wrapper };
