import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Background from "../../components/Background";
import ListAndContentCard from "../../components/ListAndContentCard";
import { handleHyperlinkUpdateAsync } from "../../sagas/actions";
import { addLink, goBack, setRequestData } from "../../store/uiSlice";
import { availableHyperlinksToListItems } from "./helpers";
import Renderer from "./renderers/Root";
import { replaceInBrackets } from "../../utils";
import { request_status } from "../../sagas/requestStatus";
import { useHistory } from "react-router";

const HalRenderer = () => {
  const { loading, schema, fullResource, availableHyperlinks } = useSelector(
    (state) => state.hateoas
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { status } = useSelector((state) => state.ui.requestData);
  const { linkHistory } = useSelector((state) => state.ui);
  const historyLength = linkHistory.length;
  const currentLink = linkHistory[historyLength - 1];
  useEffect(() => {
    if ([request_status.error, request_status.success].includes(status)) {
      dispatch(
        setRequestData({ type: "", status: request_status.not_started })
      );
    }
  }, [status, dispatch]);

  useEffect(() => {
    dispatch(handleHyperlinkUpdateAsync(currentLink.href, undefined, history));
  }, [currentLink.href, status]);

  const widgets = schema?.userInterface?.["material-ui"]?.preferredDisplay;

  const handleOnChangePage = ({ page }) => {
    dispatch(
      addLink({
        href: replaceInBrackets(
          availableHyperlinks.find.href,
          ["page", "resourcesPerPage"],
          [page + 1, fullResource?.resourcesPerPage]
        ),
      })
    );
  };

  const handleOnChangePageSize = ({ pageSize }) => {
    dispatch(
      addLink({
        href: replaceInBrackets(
          availableHyperlinks.find.href,
          ["page", "resourcesPerPage"],
          [1, pageSize]
        ),
      })
    );
  };

  return (
    <Background headerTitle={availableHyperlinks?.self?.title}>
      <ListAndContentCard
        items={availableHyperlinksToListItems(availableHyperlinks, (href) =>
          dispatch(addLink({ href }))
        )}
        Renderer={Renderer}
        rendererProps={{
          type: currentLink.type || widgets,
          loading,
          currentLink,
          onChangePage: handleOnChangePage,
          onPageSizeChange: handleOnChangePageSize,
          linkSetter: (href, type) => dispatch(addLink({ href, type })),
          onGoBack: () => dispatch(goBack()),
          comingFrom: linkHistory[linkHistory.length - 2],
        }}
      />
    </Background>
  );
};

export default HalRenderer;
