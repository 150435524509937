import { styled } from "@material-ui/core";
import BG from "../../components/Background";
import CC from "../../components/ContentCard";

export const Background = styled(({ ...props }) => (
  <BG
    contentStyle={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    {...props}
  />
))({});

export const ContentCard = styled(({ ...props }) => (
  <CC
    {...props}
    style={{
      padding: 10,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    }}
  />
))({});
