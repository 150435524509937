import { Box, List, ListItem, Typography, Divider } from "@material-ui/core";
import React from "react";
import CustomIcon from "../../../components/CustomIcon";

const LeftList = ({
  containerStyle = {},
  items,
  activeId = false,
  onClickItem,
}) => {
  return (
    <List style={{ overflow: "auto", ...containerStyle }}>
      {items.map(
        ({
          contentId = null,
          title,
          description,
          iconName,
          itemStyle,
          color,
          onClick,
          Component,
        }) => {
          const active = contentId === activeId;
          const activeStyle = active && { backgroundColor: "#00000011" };
          return (
            <>
              <ListItem
                button
                onClick={
                  Component
                    ? () => onClickItem({ contentId, Component })
                    : onClick
                }
                style={{
                  ...{ display: "flex" },
                  ...activeStyle,
                  ...itemStyle,
                }}
              >
                <CustomIcon name={iconName || "settings"} color={color} />
                <Box marginLeft={2}>
                  <Typography variant="subtitle1" color={color}>
                    {title}
                  </Typography>
                  <Typography variant="body2" color={color}>
                    {description}
                  </Typography>
                </Box>
              </ListItem>
              <Divider />
            </>
          );
        }
      )}
    </List>
  );
};

export default LeftList;
