import React, { useRef, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../pages/Login";
import Conversations from "../pages/Conversations";
import Drawer from "./Drawer";
import { Box } from "@material-ui/core";
import { DRAWER_MARGIN, DRAWER_WIDTH } from "../theme/styleConstants";
import Config from "../pages/Config";
import HalRenderer from "../pages/HalRenderer";
import Schedule from "../pages/Schedule";
import { useDispatch, useSelector } from "react-redux";
import { Client as ConversationsClient } from "@twilio/conversations";
import { setShowNewMessage } from "../store/uiSlice";
import { logout } from "../store";
import { showAlertForHTTPResponseAsync } from "../sagas/actions";

const Navigation = () => {
  const [token, setToken] = useState();
  const [status, setStatus] = useState();
  const [conversations, setConversations] = useState([]);
  const mySid = useRef("");
  const { twilioAccessToken, twilioIdentity } = useSelector(
    (state) => state.user.info
  );
  const { isLogged } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const conversationsClient = useRef();

  const initConversations = async (tkn) => {
    try {
      await ConversationsClient.create(tkn, { logLevel: "silent" })
        .then(
          (client) => {
            conversationsClient.current = client;
            conversationsClient.current.updateToken(tkn);
            client.getUser(twilioIdentity).then((user) => {
              mySid.current = user.entity.sid;
            });
            conversationsClient.current.on(
              "connectionStateChanged",
              (state) => {
                if (state === "connecting") {
                  setStatus("default");
                }
                if (state === "connected") {
                  setStatus("success");
                }
                if (state === "disconnecting") {
                  setStatus("default");
                }
                if (state === "disconnected") {
                  setStatus("warning");
                }
                if (state === "denied") {
                  setStatus("error");
                }
              }
            );
            conversationsClient.current.on(
              "conversationJoined",
              async (conversation) => {
                conversation
                  .getMessages()
                  .then(() =>
                    setConversations((prev) => [...prev, conversation])
                  );
              }
            );
            conversationsClient.current.on("conversationError", (e) => {
              console.log(e);
            });
            conversationsClient.current.on(
              "conversationLeft",
              (thisConversation) => {
                setConversations((prev) => [
                  ...prev.filter((it) => it !== thisConversation),
                ]);
              }
            );
            conversationsClient.current.on("tokenAboutToExpire", (e) =>
              console.log("WILL EXPIRE", e)
            );
            conversationsClient.current.on("tokenExpired", async () => {
              dispatch(
                showAlertForHTTPResponseAsync({ status: 401 }, "authorization")
              );
              dispatch(logout());
            });
            conversationsClient.current.on(
              "conversationUpdated",
              async ({ conversation, updateReasons }) => {
                if (updateReasons.some((item) => item === "lastMessage")) {
                  const author =
                    conversation?.messagesEntity?.messagesByIndex?.get(
                      conversation?.lastMessage?.index
                    )?.author;
                  if (author !== twilioIdentity) {
                    dispatch(setShowNewMessage({ isOpen: true }));
                  }
                }
                setConversations((prev) => [
                  ...prev.filter((item) => conversation.sid !== item.sid),
                  conversation,
                ]);
              }
            );
          },
          (e) => {
            dispatch(logout());
          }
        )
        .catch((e) => {
          dispatch(logout());
        });
    } catch (e) {
      dispatch(logout());
    }
  };

  useEffect(() => {
    const shutdown = async () => {
      if (conversationsClient.current)
        await conversationsClient.current.shutdown();
    };
    if (!isLogged) {
      shutdown();
      setToken(null);
      setConversations([]);
      setStatus(null);
    }
  }, [isLogged]);

  useEffect(() => {
    const initialization = async () => {
      setToken(twilioAccessToken);
      try {
        await initConversations(twilioAccessToken);
      } catch (e) {
        console.log(e);
        dispatch(logout());
      }
    };
    if (isLogged) {
      if (!token && twilioAccessToken) {
        initialization();
      }
    }
  }, [token, twilioAccessToken, isLogged]);

  return (
    <Router>
      <Box>
        <Drawer />
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <div style={{ paddingLeft: DRAWER_MARGIN + DRAWER_WIDTH }}>
            <Route exact path="/atender">
              <Conversations
                conversations={conversations}
                mySid={mySid}
                status={status}
                twilioIdentity={twilioIdentity}
              />
            </Route>
            <Route exact path="/gerir">
              <HalRenderer />
            </Route>
            <Route exact path="/agendar">
              <Schedule />
            </Route>
            <Route exact path="/configuracoes">
              <Config />
            </Route>
          </div>
        </Switch>
      </Box>
    </Router>
  );
};

export default Navigation;
