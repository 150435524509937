import React from "react";
import { Background, ContentCard } from "./styles";

const BackgroundAndCard = ({ children, headerTitle, loading }) => {
  return (
    <Background headerTitle={headerTitle}>
      <ContentCard loading={loading}>{children}</ContentCard>
    </Background>
  );
};

export default BackgroundAndCard;
