import { Box, Button, Dialog, Typography } from "@material-ui/core";
import React, { useState } from "react";
import theme from "../../../../theme";
import FillTemplate from "../FillTemplate";

const TemplateDialog = ({
  open,
  onClose,
  onConfirm,
  selectedTemplate: { body, title },
  isDirect,
}) => {
  const [joined, setJoined] = useState();
  const handleOnSend = () => {
    // if (Object.values(inputs).some((item) => item.length === 0)) {
    //   return;
    // }
    onConfirm(joined);
    onClose();
  };

  const onChangeTemplate = (joined) => setJoined(joined);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box height="300px" width="500px">
        <Box padding={2}>
          <Typography variant="h4">{title}</Typography>
          <FillTemplate
            isDirect={isDirect}
            templateBody={body}
            onChange={onChangeTemplate}
          />
        </Box>
      </Box>
      <Box
        border="1px solid #ccc"
        paddingBottom={1}
        paddingTop={1}
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          style={{ color: theme.palette.error.main, margin: 10 }}
          variant="text"
          onClick={onClose}
        >
          Voltar
        </Button>
        <Button
          style={{ margin: 10 }}
          color="primary"
          variant="outlined"
          onClick={handleOnSend}
        >
          Ok
        </Button>
      </Box>
    </Dialog>
  );
};

export default TemplateDialog;
