import React, { useEffect, useState } from "react";
import { Box, Button, Icon, IconButton, TextField } from "@material-ui/core";
import TemplateCollapse from "../TemplateCollapse";

const MessageBottomBar = ({ loadingState, proxy }) => {
  const [open, setOpen] = useState(false);
  const [messageValue, setMessageValue] = useState("");

  const onMessageChanged = (event) => {
    setMessageValue(event.target.value);
  };

  const sendMessage = (event) => {
    if (event) event.preventDefault();
    if (messageValue) {
      proxy.sendMessage(`${messageValue}`);
      setMessageValue("");
    }
  };

  const onKeyDown = async (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      if (!e.shiftKey) {
        e.preventDefault();
        sendMessage();
      }
    } else {
      await proxy.typing();
    }
  };

  const onConfirmTemplate = (joinedTemplate) => {
    setMessageValue(joinedTemplate);
    setOpen(false);
  };

  useEffect(() => {
    setMessageValue("");
  }, [proxy]);

  return (
    <Box>
      <Box
        marginTop="auto"
        maxHeight="200px"
        alignItems="center"
        display="flex"
        border={1}
        borderLeft={0}
        borderRight={0}
        borderColor="#ccc"
        padding={1}
      >
        <Button
          variant="outlined"
          color="secondary"
          style={{ marginLeft: 20, marginRight: 20 }}
          onClick={() => setOpen((prev) => !prev)}
        >
          {open ? "ESCONDER" : "TEMPLATES"}
        </Button>
        <TextField
          multiline
          display="flex"
          style={{
            width: "70%",
            overflow: "auto",
            maxHeight: 200,
            marginTop: "auto",
          }}
          placeholder={"Mensagem"}
          name={"message"}
          variant="outlined"
          autoComplete={"off"}
          disabled={loadingState !== "ready"}
          onChange={onMessageChanged}
          value={messageValue}
          color="secondary"
          onKeyDown={onKeyDown}
        />
        <Box display="flex" justifyContent="center" width="10%">
          <IconButton
            name="send"
            icon="send"
            disabled={loadingState !== "ready"}
            onClick={sendMessage}
            htmlType="submit"
            type={"submit"}
          >
            <Icon color="secondary">send</Icon>
          </IconButton>
        </Box>
      </Box>
      <TemplateCollapse open={open} onConfirmTemplate={onConfirmTemplate} />
    </Box>
  );
};

export default MessageBottomBar;
