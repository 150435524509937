import React from "react";
import { IconButton } from "@material-ui/core";
import { DataGrid, ptBR } from "@material-ui/data-grid";
import { Visibility } from "@material-ui/icons";
import { useSelector } from "react-redux";

const addDetailsColumn = (columns, onClick) =>
  columns?.concat([
    {
      flex: 1,
      field: "",
      headerName: "Detalhes",
      disableClickEventBubbling: true,
      renderCell: ({ row }) => {
        return (
          <IconButton
            style={{ display: "flex", margin: "auto" }}
            onClick={() => onClick(row)}
          >
            <Visibility />
          </IconButton>
        );
      },
    },
  ]);

const Pagination = ({ onPageChange, onClickItem, onPageSizeChange }) => {
  const { fullResource, schema, availableHyperlinks, availableEmbedded } =
    useSelector((state) => state.hateoas);
  const splittedUrl = availableHyperlinks?.self?.href?.split("/");
  const list = availableEmbedded?.[splittedUrl?.[splittedUrl.length - 1]];
  const columns = schema?.userInterface?.["material-ui"]?.dataGrid?.columns;

  const gridData = {
    rows:
      list.map((item) => {
        const newItem = { ...item };
        if ("canStartConversation" in newItem) {
          if (newItem.canStartConversation)
            newItem.canStartConversation = "Sim";
          else newItem.canStartConversation = "Não";
        }
        return newItem;
      }) || [],
    columns: addDetailsColumn(columns, onClickItem) || [],
  };

  return (
    <DataGrid
      {...gridData}
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      page={fullResource?.currentPage - 1}
      rowCount={fullResource?.total}
      paginationMode="server"
      pageSize={fullResource?.resourcesPerPage}
      onPageChange={onPageChange}
      autoHeight
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={[5, 10, 20]}
      componentsProps={{
        pagination: {
          labelRowsPerPage: "Linhas por página",
          labelDisplayedRows: ({ from, to, count }) =>
            `${from}-${to} de ${count}`,
        },
      }}
    />
  );
};

export default Pagination;
