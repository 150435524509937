import axios from "axios";
import { BASE_URL } from "./routes";

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const get = async (url) => {
  try {
    const response = await instance.get(url);
    return {
      hasError: false,
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return {
      hasError: true,
      error,
    };
  }
};

export const post = async (url, body) => {
  try {
    const response = await instance.post(url, body);
    return {
      hasError: false,
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return {
      hasError: true,
      error,
    };
  }
};

export const options = async (url, body) => {
  try {
    const response = await instance.post(url, body);
    return {
      hasError: false,
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return {
      hasError: true,
      error,
    };
  }
};

export default instance;
