import { createSlice } from "@reduxjs/toolkit";
import { request_status } from "../sagas/requestStatus";
import { BASE_URL } from "../services/routes";
import Traverson from "../services/traverson";
const accessToken = localStorage.getItem("token");

const INITIAL_STATE = {
  clearTrigger: false,
  linkHistory: [{ href: "/" }],
  showNewMessage: false,
  alertData: {
    type: "",
    message: "",
    show: false,
  },
  dialogData: {
    show: false,
  },
  requestData: {
    type: "GET",
    status: request_status.not_started,
  },
  navigationLinks: {},
  currentLink: "",
  displayedTitle: "Bem-vindo!",
  displayedSubtitle: "",
  lastTable: null,
  lastTablePage: 0,
  accessToken: accessToken ? accessToken : "",
  traverson: accessToken
    ? Traverson.from(BASE_URL)
        .jsonHal()
        .withRequestOptions({
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
    : Traverson.from(BASE_URL).jsonHal(),
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: INITIAL_STATE,
  reducers: {
    showAlert: (state, { payload: alertData }) => {
      let localAlertData = {
        type: alertData.type ?? "info",
        message: alertData?.message ?? "Algo inesperado aconteceu",
        show: true,
      };
      state.alertData = localAlertData;
    },
    hideAlert: (state) => {
      state.alertData.show = false;
    },
    showDialog: (state) => {
      let localDialogData = {
        show: true,
      };
      state.dialogData = localDialogData;
    },
    hideDialog: (state) => {
      state.dialogData = {
        show: false,
      };
    },
    fireClear: (state) => {
      state.clearTrigger = !state.clearTrigger;
    },
    setDisplayedTitle: (state, { payload }) => {
      state.displayedTitle = payload.displayedTitle;
    },
    setDisplayedSubtitle: (state, { payload }) => {
      state.displayedSubtitle = payload.displayedSubtitle;
    },
    setNavigationLinks: (state, { payload }) => {
      state.navigationLinks = payload.navigationLinks;
    },
    setCurrentLink: (state, { payload }) => {
      state.currentLink = payload.currentLink;
    },
    setRequestData: (state, { payload }) => {
      state.requestData = payload;
    },
    setLastTable: (state, { payload: { lastTable } }) => {
      state.lastTable = lastTable;
    },
    setLastTablePage: (state, { payload: { lastTablePage } }) => {
      state.lastTablePage = lastTablePage;
    },
    addLink: (state, { payload: { href, type } }) => {
      state.linkHistory = state.linkHistory.concat([{ href, type }]);
    },
    goBack: (state) => {
      if (state.linkHistory.length > 1) {
        state.linkHistory = state.linkHistory.slice(
          0,
          state.linkHistory.length - 1
        );
      }
    },
    resetLinkHistory: (state) => {
      state.linkHistory = [{ href: "/" }];
    },
    setShowNewMessage: (state, { payload: { isOpen } }) => {
      state.showNewMessage = isOpen;
    },
  },
});

export const alertDataSelector = (state) => state.ui.alertData;

export const {
  fireClear,
  hideAlert,
  hideDialog,
  setDisplayedSubtitle,
  setDisplayedTitle,
  showAlert,
  showDialog,
  setNavigationLinks,
  setCurrentLink,
  setRequestData,
  setLastTable,
  setLastTablePage,
  addLink,
  goBack,
  resetLinkHistory,
  setShowNewMessage,
} = uiSlice.actions;

export default uiSlice.reducer;
