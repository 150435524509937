import React, { useState } from "react";
import { useSelector } from "react-redux";
import Background from "../../components/Background";
import ConversationsList from "./components/ConversationsList";
import Conversation from "./components/Conversation";
import { Divider } from "@material-ui/core";
import { Redirect } from "react-router";
import { userSelector } from "../../store/userSlice";

const Conversations = ({ conversations, twilioIdentity, mySid, status }) => {
  const { isLogged } = useSelector(userSelector);
  const [selectedConversationSid, setSelectedConversationSid] = useState();

  const handleOnConversationClick = ({ sid }) => {
    if (sid !== selectedConversation?.sid) {
      if (selectedConversation)
        selectedConversation.removeAllListeners("messageAdded");
      setSelectedConversationSid(sid);
    }
  };

  if (!isLogged) {
    return <Redirect to="/" />;
  }

  const selectedConversation = conversations.find(
    (it) => it.sid === selectedConversationSid
  );

  return (
    <Background
      showHeader={false}
      centerContent={false}
      contentStyle={{ display: "flex", flexDirection: "row", height: "100vh" }}
    >
      <ConversationsList
        style={{ width: "30%" }}
        conversations={conversations}
        selectedConversationSid={selectedConversationSid}
        onConversationClick={handleOnConversationClick}
        identity={twilioIdentity}
        status={status}
      />
      <Divider orientation="vertical" />
      {selectedConversation && (
        <Conversation
          style={{ width: "70%", height: "100%", overflow: "auto" }}
          proxy={selectedConversation}
          mySid={mySid}
          myIdentity={twilioIdentity}
        />
      )}
    </Background>
  );
};

export default Conversations;
