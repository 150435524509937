import React from "react";
import Headers from "../Headers";
import { Container, Content } from "./styles";

const Background = ({
  children,
  headerTitle,
  showHeader = true,
  contentStyle = {},
  centerContent = true,
}) => {
  const centralized = centerContent
    ? {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }
    : {};
  return (
    <Container>
      {showHeader && <Headers.Default title={headerTitle} />}
      <Content contentStyle={{ ...contentStyle, ...centralized }}>
        {children}
      </Content>
    </Container>
  );
};

export default Background;
