import { Box } from "@material-ui/core";
import React from "react";
import FillTemplate from "../../../Conversations/components/FillTemplate";

const FillTemplateStage = ({ templateBody, onChange }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      width={0.8}
      margin="auto"
      border="1px solid #ccc"
      borderRadius={10}
      padding={2}
      bgcolor="white"
    >
      <FillTemplate onChange={onChange} templateBody={templateBody} />
    </Box>
  );
};

export default FillTemplateStage;
