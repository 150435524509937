import React from "react";
import { Backdrop, CircularProgress, Paper } from "@material-ui/core";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const ContentCard = ({ style = {}, children }) => {
  const classes = useStyles({ style });
  const history = useHistory();
  const loading = useSelector((state) => state.hateoas.loading);
  return (
    <>
      <Paper elevation={0} classes={{ root: classes.paper }}>
        {children}
        <Backdrop
          classes={{ root: classes.backdrop }}
          open={loading && history.location.pathname === "/gerir"}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      </Paper>
    </>
  );
};

export default ContentCard;
