import { Divider } from "@material-ui/core";
import React, { useState } from "react";
import ContentCard from "../ContentCard";
import LeftList from "./LeftList";
import ContentRenderer from "./ContentRenderer";

const ListAndContentCard = ({
  items = [],
  Renderer = ContentRenderer,
  rendererProps = {},
}) => {
  const [selectedItem, setSelectedItem] = useState(
    items.filter((i) => i?.Component)[0] || {}
  );

  const handleOnClickItem = (item) => {
    setSelectedItem(item);
  };

  return (
    <ContentCard style={{ display: "flex", flexDirection: "row" }}>
      {items.length > 0 && (
        <LeftList
          containerStyle={{ width: "33%" }}
          activeId={selectedItem?.contentId}
          items={items}
          onClickItem={handleOnClickItem}
        />
      )}

      <Divider orientation="vertical" />
      <Renderer selectedItem={selectedItem} {...rendererProps} />
    </ContentCard>
  );
};

export default ListAndContentCard;
