import { makeStyles, Tooltip, withStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  messageTitle: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-all",
    overflow: "hidden",
  },
  lastMsgBox: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: 12,
  },
  container: {
    backgroundColor: ({ activeChannel }) =>
      activeChannel ? theme.palette.primary.light : "#00000000",
    height: 100,
    display: "flex",
    "&:hover": {
      backgroundColor: ({ activeChannel }) =>
        activeChannel ? theme.palette.primary.main : "#00000011",
    },
  },
}));

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: 12,
  },
}))(Tooltip);

export default useStyles;
