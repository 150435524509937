import React from "react";
import { Box, LinearProgress } from "@material-ui/core";
import Form from "./Form";
import { useSelector } from "react-redux";
import { UpperButtons } from "../helpers";
import Pagination from "../../../components/Pagination";

export const UITypes = {
  table: "widgets:table",
  form_read: "widgets:form",
  form_create: "form_create",
};

const RootRenderer = ({
  type,
  onChangePage,
  onPageSizeChange,
  linkSetter,
  onGoBack,
}) => {
  const { loading, availableHyperlinks } = useSelector(
    (state) => state.hateoas
  );
  return (
    <Box display="flex" flexDirection="column" width="100%" overflow="auto">
      {loading && <LinearProgress />}
      {type === UITypes.table && (
        <Box padding={1} display="flex" flexDirection="column">
          <UpperButtons
            onGoBack={onGoBack}
            onClickCreate={() => {
              linkSetter(availableHyperlinks.self.href, UITypes.form_create);
            }}
            isTable
          />
          <Pagination
            onClickItem={(row) => {
              linkSetter(row._links.self.href);
            }}
            onPageChange={onChangePage}
            onPageSizeChange={onPageSizeChange}
          />
        </Box>
      )}
      {type === UITypes.form_create && <Form onGoBack={onGoBack} isCreation />}
      {type === UITypes.form_read && <Form isRead onGoBack={onGoBack} />}
      {!Object.values(UITypes).includes(type) && null}
    </Box>
  );
};

export default RootRenderer;
